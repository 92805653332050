import React from "react";
import "../../style/Modals/Modals.css"
import "../../style/Common/Common.css"
import "../../style/Form/Form.css"
import "../../style/Tables/Tables.css"
import { Form } from "react-bootstrap";
import { Button, Table } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);

function TableModals(props) {
  return (
    <>
    <Modal {...props} size="lg"  className="table_modal" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header  closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          Project Category
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table  responsive  >
            <thead>
              <tr>
                <th>#</th>
                <th>Category Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr id="cat-1">
                <td>1</td>
                <td>Laravel</td>
                <td>
                <Button  className="btn btn-sm btn-danger btn-rounded delete-category">
                    Remove
                  </Button>
                </td>
              </tr>
              <tr id="cat-2">
                <td>2</td>
                <td>Java</td>
                <td>
                <Button  className="btn btn-sm btn-danger btn-rounded delete-category">
                    Remove
                  </Button>
                </td>
              </tr>
              <tr id="cat-3">
                <td>3</td>
                <td>Developmet</td>
                <td>
                <Button  className="btn btn-sm btn-danger btn-rounded delete-category">
                    Remove
                  </Button>
                </td>
              </tr>
              <tr id="cat-5">
                <td>4</td>
                <td>Digital Marketing</td>
                <td>
                <Button  className="btn btn-sm btn-danger btn-rounded delete-category">
                    Remove
                  </Button>
                </td>
              </tr>
              <tr id="cat-6">
                <td>5</td>
                <td>Mobile App Development</td>
                <td>
                <Button  className="btn btn-sm btn-danger btn-rounded delete-category">
                    Remove
                  </Button>
                </td>
              </tr>
              <tr id="cat-7">
                <td>6</td>
                <td>Internal Development</td>
                <td>
                <Button  className="btn btn-sm btn-danger btn-rounded delete-category">
                    Remove
                  </Button>
                </td>
              </tr>
              <tr id="cat-8">
                <td>7</td>
                <td>Wordpress</td>
                <td>
                <Button  className="btn btn-sm btn-danger btn-rounded delete-category">
                    Remove
                  </Button>
                </td>
              </tr>
              <tr id="cat-9">
                <td>8</td>
                <td>E-Commerce Development</td>
                <td>
                <Button  className="btn btn-sm btn-danger btn-rounded delete-category">
                    Remove
                  </Button>
                </td>
              </tr>
              <tr id="cat-10">
                <td>9</td>
                <td>Internal Designing</td>
                <td>
                <Button  className="btn btn-sm btn-danger btn-rounded delete-category">
                    Remove
                  </Button>
                </td>
              </tr>
              <tr id="cat-11">
                <td>10</td>
                <td>Designing</td>
                <td>
                <Button  className="btn btn-sm btn-danger btn-rounded delete-category">
                    Remove
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>

          <Form>
            <Form.Group controlId="category_name">
              <Form.Label>Category Name</Form.Label>
              <Form.Control type="text" placeholder="Enter category name" />
            </Form.Group>
            <Button  className="btn btn-success save-btn my-2" type="submit">
            <FontAwesomeIcon icon="fa-solid fa-check" /> Save
            </Button>
          </Form>
        </Modal.Body>
    </Modal>
    </>
  );
}

export default TableModals;
