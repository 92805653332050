import React, { useState, useRef } from "react";
import JoditEditor from "jodit-react";
import "./Task.css";
import "../Veiw_Details.css";
import "../../projectsec.css";
import "../../style/Modals/Modals.css";
import "../../style/Form/Form.css";
import "../../style/Common/Common.css";
import "../../style/Tables/Tables.css";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ProjectCategoryModal from "../../Modals/ProjectCategoryModal/ProjectCategoryModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import DeleteModal from "../../Modals/DeleteModal/DeleteModal";
import {
  Button,
  Card,
  Dropdown,
  Modal,
  Offcanvas,
  Pagination,
  Table,
} from "react-bootstrap";
import OffcanvasView from "../../OffcanvasView/OffcanvasView";
library.className = fas;

function Tasks(props) {
  const [deleteModalShow, setDeleteModalShow] = React.useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [status, setStatus] = useState("Incomplete");
  const [modalShow, setModalShow] = React.useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [showForm, setShowForm] = useState(false);

  const editor = useRef(null);
  const [content, setContent] = useState("");

  const handleStatusChange = (newStatus) => {
    setStatus(newStatus);
  };

  const handleShowForm = () => {
    setShowForm(true);
  };

  const handleCloseForm = () => {
    setShowForm(false);
  };

  return (
    <>
      {/* Tasks tab content start (smita) */}
      <div className="Tasks">
        <div className="row">
          {/* onclick on  Task Task btn  section start (smita ) */}
          {showForm && (
            <div class="col-md-12 ">
              <div className="task_details_form start">
                <div className="card">
                  <div className=" card-header new_task_header">
                    <div id=" card-title contained-modal-title-vcenter">
                      <h6 className="lg-text">
                        {" "}
                        <FontAwesomeIcon icon="fa-solid fa-plus me-2" />
                        New Tasks
                      </h6>
                    </div>

                    <div className="close_btn" onClick={handleCloseForm}>
                      <FontAwesomeIcon icon="fa-solid fa-times me-2" />
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="field_top_space">
                      <div className="row">
                        <div className="col-md-12">
                          <Form>
                            <Form.Group controlId="Title">
                              <Form.Label className="required">
                                Title
                              </Form.Label>
                              <FontAwesomeIcon
                                icon="fa-solid fa-circle-info"
                                className="text-dark gear-icon"
                              />
                              <Form.Control
                                type="text"
                                name="Title"
                                placeholder="Title"
                              />
                              <Form.Control type="hidden" name="Title" />
                            </Form.Group>
                          </Form>
                        </div>
                      </div>
                    </div>

                    <div className="field_top_space">
                      <div className="row">
                        <div className="col-md-12">
                          <Form>
                            <Form.Group controlId="Description">
                              <Form.Label className="">Description</Form.Label>
                            </Form.Group>
                            <JoditEditor
                              ref={editor}
                              value={content}
                              tabIndex={1} // tabIndex of textarea
                              onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                              onChange={(newContent) => {}}
                            />
                          </Form>
                        </div>
                      </div>
                    </div>

                    <div className="field_top_space">
                      <div className="row">
                        <div className="col-md-6">
                          <Form>
                            <Form.Group controlId="Joining_Date">
                              <Form.Label className="required">
                                Joining Date
                              </Form.Label>
                              <div>
                                <DatePicker
                                  selected={startDate}
                                  onChange={(date) => setStartDate(date)}
                                  name="start_date"
                                  id="start_date"
                                  autoComplete="off"
                                  className="form-control"
                                />
                              </div>
                            </Form.Group>
                          </Form>
                        </div>

                        <div className="col-md-6">
                          <Form>
                            <Form.Group controlId="Due_Date">
                              <Form.Label className="required">
                                Due Date
                              </Form.Label>
                              <div>
                                <DatePicker
                                  selected={endDate}
                                  onChange={(date) => setEndDate(date)}
                                  name="end_date"
                                  id="end_date"
                                  autoComplete="off"
                                  className="form-control"
                                />
                              </div>
                            </Form.Group>
                          </Form>
                        </div>
                      </div>
                    </div>

                    <div className="field_top_space">
                      <div className="row">
                        <div className="col-md-12">
                          <Form>
                            <Form.Group controlId="Milestones">
                              <Form.Label className="">Milestones</Form.Label>
                              <Form.Select as="select" name="Milestones">
                                <option value="1">--</option>
                                <option value="2">Java</option>
                                <option value="3">Development</option>
                                <option value="5">Digital Marketing</option>
                                <option value="6">
                                  Mobile App Development
                                </option>
                                <option value="7">Internal Development</option>
                                <option value="8">WordPress</option>
                                <option value="9">
                                  E-Commerce Development
                                </option>
                                <option value="10">Internal Designing</option>
                                <option value="11">Designing</option>
                              </Form.Select>
                            </Form.Group>
                          </Form>
                        </div>
                      </div>
                    </div>

                    <div className="field_top_space">
                      <div className="row">
                        <div className="col-md-12">
                          <Form>
                            <Form.Group controlId="Assigned_To">
                              <Form.Label className="required">
                                Assigned To
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="Assigned_To"
                                placeholder="Assigned To"
                              />
                              <Form.Control type="hidden" name="Assigned_To" />
                              <span className="help-block">
                                Employee will login using this email.
                              </span>
                            </Form.Group>
                          </Form>
                        </div>
                      </div>
                    </div>

                    <div className="field_top_space">
                      <div className="row">
                        <div className="col-md-12">
                          <Form>
                            <Form.Group controlId="Task Category                                                                        ">
                              <Form.Label className="">
                                Task Category
                                <Button
                                  className="btn green_btn btn-sm text-success me-2"
                                  onClick={() => setModalShow(true)}
                                >
                                  <FontAwesomeIcon icon="fa-solid fa-plus me-2" />
                                  Add Category
                                </Button>
                              </Form.Label>
                              <Form.Select
                                as="select"
                                name="Task Category                                                                        "
                              >
                                <option value="1">--</option>
                                <option value="2">Java</option>
                                <option value="3">Development</option>
                                <option value="5">Digital Marketing</option>
                                <option value="6">
                                  Mobile App Development
                                </option>
                                <option value="7">Internal Development</option>
                                <option value="8">WordPress</option>
                                <option value="9">
                                  E-Commerce Development
                                </option>
                                <option value="10">Internal Designing</option>
                                <option value="11">Designing</option>
                              </Form.Select>
                            </Form.Group>
                          </Form>
                        </div>
                      </div>
                    </div>

                    <div className="field_top_space">
                      <div className="row">
                        <div class="col-md-12">
                          <Form.Group className="form-group">
                            <Form.Label className="control-label">
                              Priority
                            </Form.Label>

                            <div>
                              <Form.Check
                                type="radio"
                                name="priority"
                                id="radio13"
                                value="high"
                                label="High"
                                className="text-danger radio radio-danger me-2"
                              />
                              <Form.Check
                                type="radio"
                                name="priority"
                                id="radio14"
                                value="medium"
                                label="Medium"
                                defaultChecked
                                className="text-warning radio radio-warning me-2"
                              />
                              <Form.Check
                                type="radio"
                                name="priority"
                                id="radio15"
                                value="low"
                                label="Low"
                                className="text-success radio radio-success me-2"
                              />
                            </div>
                          </Form.Group>
                        </div>
                      </div>
                    </div>

                    <Form>
                      <Button
                        className="btn btn-success save-btn my-2"
                        type="submit"
                      >
                        <FontAwesomeIcon icon="fa-solid fa-check" /> Save
                      </Button>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* onclick on  Task Task btn  section end (smita ) */}

          <div className="col-md-12">
            {/* Tasks form start (smita ) */}
            <div className="row ">
              <div className="col-md-12">
                <div className="Tasks">
                  <h2 className="page-title">Tasks</h2>
                  <div className="row m-b-10">
                    <div className="col-md-6">
                      <Button
                        id="show-new-task-panel"
                        className="btn green_btn btn-sm text-success me-2"
                        onClick={handleShowForm}
                      >
                        <FontAwesomeIcon icon="fa-solid fa-plus me-2" />
                        New Task{" "}
                      </Button>
                      <Button
                        className="btn info_btn btn-sm text-info me-2"
                        onClick={() => setModalShow(true)}
                      >
                        <FontAwesomeIcon icon="fa-solid fa-plus me-2" />
                        Task Category{" "}
                      </Button>

                      <ProjectCategoryModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Tasks form end (smita ) */}

            {/* Tasks table start  (smita) */}
            <div className="Milestone_table mt-5">
              <div className="row justify-content-between">
                <div className="col-md-2">
                  <div className="dataTables_length " id="timelog-table_length">
                    <Form.Label className="flex-box">
                      <p className="xl-text mb-0 me-1">Show</p>
                      <Form.Select
                        as="select"
                        name="timelog-table_length"
                        aria-controls="timelog-table"
                        className="form-control input-sm"
                      >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </Form.Select>{" "}
                      <p className="xl-text mb-0 ms-1 ms-1 ">entries</p>
                    </Form.Label>
                  </div>
                </div>
                <div className="col-md-3">
                  <div id="timelog-table_filter" className="dataTables_filter ">
                    <Form.Label className="flex-box">
                      <p className="xl-text mb-0 me-1">Search:</p>
                      <Form.Control
                        type="search"
                        className="form-control input-sm"
                        placeholder=""
                        aria-controls="timelog-table"
                      />
                    </Form.Label>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12">
                  <Table
                    bordered
                    hover
                    responsive="md"
                    className="toggle-circle default footable-loaded footable dataTable no-footer dtr-inline"
                    id="tasks-table"
                    role="grid"
                    aria-describedby="tasks-table_info"
                  >
                    <thead>
                      <tr role="row">
                        <th
                          className="sorting_desc"
                          tabIndex="0"
                          aria-controls="tasks-table"
                          rowSpan="1"
                          colSpan="1"
                          aria-sort="descending"
                          aria-label="Id: activate to sort column ascending"
                        >
                          Id
                        </th>
                        <th
                          className="sorting"
                          tabIndex="0"
                          aria-controls="tasks-table"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Task: activate to sort column ascending"
                        >
                          Task
                        </th>
                        <th
                          className="sorting"
                          tabIndex="0"
                          aria-controls="tasks-table"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Client: activate to sort column ascending"
                        >
                          Client
                        </th>
                        <th
                          className="sorting"
                          tabIndex="0"
                          aria-controls="tasks-table"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Assigned To: activate to sort column ascending"
                        >
                          Assigned To
                        </th>
                        <th
                          className="sorting"
                          tabIndex="0"
                          aria-controls="tasks-table"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Assigned By: activate to sort column ascending"
                        >
                          Assigned By
                        </th>
                        <th
                          className="sorting"
                          tabIndex="0"
                          aria-controls="tasks-table"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Due Date: activate to sort column ascending"
                        >
                          Due Date
                        </th>
                        <th
                          className="sorting"
                          tabIndex="0"
                          aria-controls="tasks-table"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Status: activate to sort column ascending"
                        >
                          Status
                        </th>
                        <th
                          className="sorting"
                          tabIndex="0"
                          aria-controls="tasks-table"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Action: activate to sort column ascending"
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="odd">
                        <td>1235</td>

                        <td>
                          <p
                            className="lh-text text-primary"
                            onClick={handleShow}
                          >
                            {" "}
                            Demo
                          </p>
                        </td>

                        <td>Smita Lad</td>

                        <td>Smita Lad</td>

                        <td>Smita Lad</td>

                        <td>
                          <p className="text-danger lg-text">12-02-2024</p>
                        </td>

                        <td>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="light"
                              id="dropdown-basic"
                              className={
                                status === "Incomplete"
                                  ? "text-danger lg-text"
                                  : "text-success lg-text"
                              }
                            >
                              {status} <span className="caret"></span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item
                                className="lg-text"
                                onClick={() => handleStatusChange("Incomplete")}
                              >
                                Incomplete{" "}
                                <span
                                  style={{
                                    width: "15px",
                                    height: "15px",
                                    borderColor: "#d21010",
                                    background: "#d21010",
                                  }}
                                  className="btn btn-warning btn-small btn-circle"
                                >
                                  &nbsp;
                                </span>
                              </Dropdown.Item>
                              <Dropdown.Item
                                className="lg-text"
                                onClick={() => handleStatusChange("Completed")}
                              >
                                Completed{" "}
                                <span
                                  style={{
                                    width: "15px",
                                    height: "15px",
                                    borderColor: "#679c0d",
                                    background: "#679c0d",
                                  }}
                                  className="btn btn-warning btn-small btn-circle"
                                >
                                  &nbsp;
                                </span>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>

                        <td>
                          <Button
                            variant="info"
                            size="sm"
                            className="btn-outline edit-members me-2"
                            onClick={handleShowForm}
                          >
                            <FontAwesomeIcon icon="fa-solid fa-pen edit" />
                          </Button>

                          <Button
                            variant="danger"
                            size="sm"
                            className="btn-outline delete-members "
                            onClick={() => setDeleteModalShow(true)}
                            
                          >
                            <FontAwesomeIcon icon="fa-solid fa-trash delete" />
                          </Button>
                          <DeleteModal
                        show={deleteModalShow}
                        onHide={() => setDeleteModalShow(false)}
                      />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>

              <div className="row justify-content-between">
                <div className="col-md-6">
                  <div
                    className="dataTables_info"
                    id="timelog-table_info"
                    role="status"
                    aria-live="polite"
                  >
                    <p className="sm-text">Showing 0 to 0 of 0 entries</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="Pagination_end">
                    <nav aria-label="..." className="templateNav">
                      <Pagination>
                        <Pagination.Prev className="sm-text">
                          Previous
                        </Pagination.Prev>
                        <Pagination.Item className="sm-text">1</Pagination.Item>
                        <Pagination.Item className="sm-text" active>
                          2
                        </Pagination.Item>
                        <Pagination.Item className="sm-text">3</Pagination.Item>
                        <Pagination.Next className="sm-text">
                          {" "}
                          Next
                        </Pagination.Next>
                      </Pagination>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            {/* Tasks table end (smita) */}

            <div className="offcanvas-sec">
              <Offcanvas
                show={show}
                onHide={handleClose}
                placement="end"
                style={{ width: "80%" }}
              >
                <Offcanvas.Header className="offcanvas-heading" closeButton>
                  <Offcanvas.Title> Task </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <OffcanvasView />
                </Offcanvas.Body>
              </Offcanvas>
            </div>
          </div>
        </div>
      </div>
      {/* Tasks tab content end (smita) */}
    </>
  );
}

export default Tasks;
