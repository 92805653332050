import { createContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import toast, { Toaster } from "react-hot-toast";

import {
  getData,
  postData,
  getEditData,
  editData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "./api";

import {
  Per_Page_Dropdown,
  RequiredIs,
  Select2Data,
  getDimension,
  Select3Data,
} from "./common";

export const Context = createContext();

const AppContext = ({ children }) => {
  const [complaintstatus, setComplaintStatus] = useState({});
  // const IMG_URL = "http://127.0.0.1:1621";
  // const IMG_URL = "http://itpulsenodenew.profcymabackend.com:1621";
  const IMG_URL = "https://itpulsenodenew.profcymabackend.com:1621";

  const navigate = useNavigate();

  // const [language, setLanguage] = useState(1);
  const [signin, setSignin] = useState(false);
  const [usertype, setUsertype] = useState("");
  const [userdata, setUserData] = useState({});
  const [token, setToken] = useState("");
  const [isAllow, setisAllow] = useState([]);

  const minLength = 2; // Set your desired minimum length
  const maxLength = 30; // Set your desired maximum length

  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const getuserData = async (id) => {
    const response = await getData(`/login/${id}`, { id: id });
    console.log("response", response);
    await setUserData(response?.data?.data);
    await setisAllow(response?.data?.permissions);
  };




  useEffect(() => {
    isTokenValid();
  }, [signin]);

  const isTokenValid = async () => {
    const token = Cookies.get("it_pulse_security");

    if (token) {
      // Decode the token to get the expiration time
      const decodedToken = JSON.parse(atob(token.split(".")[1]));
      const currentTime = Date.now() / 1000;

      // Check if the token has expired
      if (decodedToken.exp < currentTime) {
        Cookies.remove("it_pulse_security", { path: "/" });
        setSignin(false);
        setUsertype("");
        setisAllow([]);
        navigate("/");
      } else {
        setisAllow(decodedToken?.permissions);
        getuserData(decodedToken.user);
        setUsertype(decodedToken.rolename);
        setSignin(true);
      }
    } else {
      setisAllow([]);
      setSignin(false);
      setUsertype("");
      navigate("/");
    }
  };

  const ErrorNotify = (name) => {
    toast.error(`${name} deleted successfully.`, {
      position: "top-right",
      autoClose: 100,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { background: "red", color: "white" },
    });
  };

  return (
    <Context.Provider
      value={{
        getData,
        postData,
        // getEditData,
        // editData,
        editStatusData,
        deleteData,
        minLength,
        maxLength,
        signin,
        setSignin,
        usertype,
        setUsertype,
        userdata,
        setUserData,
        getDownloadDataExcel,
        ErrorNotify,
        setisAllow,
        isAllow,
        Per_Page_Dropdown,
        IMG_URL,
        // token,
        // setToken,
        RequiredIs,
        Select2Data,
        Select3Data,
        getDimension,
        complaintstatus,
        setComplaintStatus,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default AppContext;
