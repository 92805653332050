import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import Offcanvas from "react-bootstrap/Offcanvas";
import Card from "react-bootstrap/Card";

import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import Select from "react-select";
import { CancelButton, SaveButton } from "../../../common/Button";
import ModalSave from "../../../common/ModalSave";
import Validation from "../../../common/FormValidation";
library.add(fas);

const EditOffCanvance = (props) => {
  const { postData, getData } = useContext(Context);

  const id = props.show;
  const [admin, setAdmin] = useState([]);
  const [manager, setManager] = useState([]);
  const [formData, setFormData] = useState({
    id: "",
    admin_id: "",
    manager_id: "",
  });

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.name]: e });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = await Validation.FormValidation({
      classname: ".managerclass",
    });

    const validationErrorsSelect = Validation.SelectValidation({
      classname: ".managerclass",
    });
    if (validationErrors && validationErrorsSelect) {
      try {
        const response = await postData(
          `/co_ordinator_management/manager/${id}`,
          formData
        );
        if (response.success) {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        } else {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const GetEditData = async () => {
    const response = await getData(`/co_ordinator_management/manager/${id}`);
    setFormData(response?.data);
  };

  useEffect(() => {
    GetEditData();
    getSelectData();
  }, []);

  // getselecteddata
  const getSelectData = async (name = null, id = null) => {
    const res1 = await getData("/masters/alladmins");
    setAdmin(res1.data);

    const res2 = await getData("/masters/allmangersroles");
    setManager(res2.data);
  };

  const errorStyle = {
    color: "red",
    marginLeft: "5px",
  };

  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded">
            <Card.Body>
              <Card.Title>Edit Manager </Card.Title>
              <hr />
              <Container>
                <Row>
                  <Col md={12}>
                    <Container>
                      <Row className="">
                        <Col xxl={12} xl={12} md={10}>
                          <Form
                            onSubmit={handleSubmit}
                            role="form"
                            className="managerclass"
                          >
                            <Row>
                              <Col md={6}>
                                <div className="main-form-section mt-5">
                                  <Row className="row justify-content-center mb-2">
                                    <Form.Label column sm={3}>
                                      Admin
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Select
                                        name="admin_id"
                                        className="custom-select input-mandatory"
                                        onChange={handleChange}
                                        value={formData.admin_id}
                                        options={admin}
                                      />
                                      <span className="error-message"></span>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="main-form-section mt-5">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      Manager
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Select
                                        name="manager_id"
                                        className="custom-select input-mandatory"
                                        onChange={handleChange}
                                        value={formData?.manager_id}
                                        options={manager}
                                      />
                                      <span className="error-message"></span>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>

                              <Row className="mt-5 pb-3">
                                <div className="d-flex justify-content-center">
                                  <Link>
                                    <CancelButton
                                      name={"cancel"}
                                      handleClose={props.handleClose}
                                    />
                                  </Link>
                                  <SaveButton
                                    name={"save"}
                                    handleSubmit={handleSubmit}
                                  />
                                </div>
                              </Row>
                            </Row>
                          </Form>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
