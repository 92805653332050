import React, { useContext, useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { format } from "date-fns";
import Offcanvas from "react-bootstrap/Offcanvas";
import Card from "react-bootstrap/Card";
import { DatePicker } from "antd";
import ModalSave from "../../../common/ModalSave";
import Validation from "../../../common/FormValidation";
import { CancelButton, SaveButton } from "../../../common/Button";
import Select from "react-select";
import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";

const { RangePicker } = DatePicker;
const AddOffCanvance = (props) => {
  const { postData, getData } = useContext(Context);

  const [leavetype, setLeavetype] = useState([]);
  const [users, setUsers] = useState([]);
  const [formData, setFormData] = useState({
    leave_type_id: "",
    title: "",
    user_id: "",
    duration: "",
    status: "",
    date: "",
  });

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const [dates, setDates] = useState([]);

  const handleChange = (e) => {
    if (
      e?.name === "leave_type_id" ||
      e?.name === "user_id" ||
      e?.name === "status"
    ) {
      setFormData({ ...formData, [e.name]: e });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  useEffect(() => {
    setFormData({ ...formData, date: dates });
  }, [dates]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = await Validation.FormValidation({
      classname: ".leaveclass",
    });

    const validationErrorsSelect = Validation.SelectValidation({
      classname: ".leaveclass",
    });

    if (validationErrors && validationErrorsSelect) {
      try {
        const response = await postData("/hrm/leave", formData);
        if (response.success) {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        } else {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const errorStyle = {
    color: "red",
    marginLeft: "5px",
  };

  const successStyle = {
    color: "green",
    marginLeft: "5px",
  };

  const [status, setStatus] = useState([
    {
      value: "Approved",
      name: "status",
      label: "Approved",
    },
    {
      value: "Rejected",
      name: "status",
      label: "Rejected",
    },
    {
      value: "Pending",
      name: "status",
      label: "Pending",
    },
  ]);

  const GetAllLeavetype = async () => {
    const response = await getData("/masters/leave_type");

    const option = [];
    if (response?.success) {
      response?.data?.map((data) => {
        option.push({
          value: data.id,
          name: "leave_type_id",
          label: data.name,
        });
      });
    }
    setLeavetype(option);
  };

  const GetAllUsers = async () => {
    const response = await getData("/masters/allusers");

    const option = [];
    if (response?.success) {
      response?.data?.map((data) => {
        option.push({
          value: data.userid,
          name: "user_id",
          label: data.user.name,
        });
      });
    }
    setUsers(option);
  };

  useEffect(() => {
    GetAllLeavetype();
    GetAllUsers();
  }, []);

  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton>
          {/* <Offcanvas.Title>Add Leave</Offcanvas.Title> */}
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded">
            <Card.Body>
              <Card.Title>Add Leave</Card.Title>
              <hr />
              <Container>
                <Row>
                  <Col md={12}>
                    <Container>
                      <Row className="">
                        <Col xxl={12} xl={12} md={10}>
                          <Form
                            onSubmit={handleSubmit}
                            role="form"
                            className="leaveclass"
                          >
                            <Row>
                              <Col md={6}>
                                <div className="main-form-section mt-5">
                                  <Row className="row justify-content-center mb-2">
                                    <Form.Label column sm={3}>
                                      Employee{" "}
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Select
                                        name="user_id"
                                        className="custom-select"
                                        onChange={handleChange}
                                        value={formData?.user_id}
                                        options={users}
                                      />

                                      <span className="error-message"></span>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="main-form-section mt-5">
                                  <Row className="row justify-content-center mb-2">
                                    <Form.Label column sm={3}>
                                      Leave Type{" "}
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Select
                                        name="leave_type_id"
                                        className="input-mandatory"
                                        onChange={handleChange}
                                        value={formData?.leave_type_id}
                                        options={leavetype}
                                      />

                                      <span className="error-message"></span>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="main-form-section mt-5">
                                  <Row className="row justify-content-center mb-2">
                                    <Form.Label column sm={3}>
                                      Status{" "}
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Select
                                        name="status"
                                        className="input-mandatory"
                                        onChange={handleChange}
                                        value={formData?.status}
                                        options={status}
                                      />

                                      <span className="error-message"></span>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="main-form-section mt-5">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      Select Duration
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Form.Check
                                        inline
                                        label="Single"
                                        value={"Single"}
                                        name="duration"
                                        type="radio"
                                        onClick={handleChange}
                                      />
                                      <Form.Check
                                        inline
                                        label="Multiple"
                                        value={"Multiple"}
                                        name="duration"
                                        onClick={handleChange}
                                        type="radio"
                                      />
                                      <Form.Check
                                        inline
                                        label="Half Day"
                                        value={"Half Day"}
                                        name="duration"
                                        onClick={handleChange}
                                        type="radio"
                                      />

                                      <span className="error-message"></span>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="main-form-section mt-5">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      Reason for absence
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="text"
                                            name="title"
                                            value={formData?.title}
                                            onChange={handleChange}
                                            placeholder="Reason for absence"
                                            id="inputEmail3"
                                            className="input-mandatory"
                                          />
                                        </InputGroup>
                                        <span className="error-message"></span>
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="main-form-section mt-5">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      Date
                                    </Form.Label>
                                    <Col sm={6}>
                                      {formData.duration === "Single" ||
                                      formData.duration === "Half Day" ? (
                                        <DatePicker
                                          className="input-mandatory"
                                          onChange={(date) => {
                                            if (date) {
                                              const formattedDate = format(
                                                new Date(date),
                                                "yyyy-MM-dd"
                                              );
                                              setDates([formattedDate]);
                                            }
                                          }}
                                        />
                                      ) : (
                                        <RangePicker
                                          className="input-mandatory"
                                          onChange={(values) => {
                                            if (values && values.length > 0) {
                                              const formattedDates = values.map(
                                                (item) =>
                                                  `${item.$y}-${++item.$M}-${
                                                    item.$D
                                                  }`
                                              );
                                              setDates(formattedDates);
                                            }
                                          }}
                                        />
                                      )}
                                      <span className="error-message"></span>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>

                              {/* </Row> */}

                              <Row className="mt-5 pb-3">
                                <div className="d-flex justify-content-center">
                                  <Link>
                                    <CancelButton
                                      name={"cancel"}
                                      handleClose={props.handleClose}
                                    />
                                  </Link>
                                  <SaveButton
                                    name={"save"}
                                    handleSubmit={handleSubmit}
                                  />
                                </div>
                              </Row>
                              {/* </Col> */}
                            </Row>
                          </Form>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas>
      {showModal.code ? (
        <ModalSave message={showModal.message} showErrorModal={true} />
      ) : null}
      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};
export default AddOffCanvance;
