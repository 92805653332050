import React, { useContext, useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Login from "./Components/Admin/Login/Login";
import IndexComplaint from "./Components/complaint-manage-dashboard/IndexComplaint";
import IndexLead from "./Components/lead-dashboard/IndexLead";
import IndexAdmin from "./Components/Admin/IndexAdmin";
import { Context } from "./utils/context";
import ComplaintRoutes from "./Components/complaint-management/ComplaintRoutes";
import { IDS } from "./utils/common";

const App = () => {
  const { signin, usertype, isAllow } = useContext(Context);
  const location = useLocation();
  const [headerText, setHeaderText] = useState(location.pathname);

  useEffect(() => {
    setHeaderText(location.pathname);
  }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
      </Routes>
      {signin ? (
        usertype === "tele-caller" ? (
          <IndexLead />
        ) : usertype === "complaint" ? (
          <ComplaintRoutes />
        ) : usertype?.length ? (
          <IndexAdmin />
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
      {/* <ComplaintRoutes /> */}
    </>
  );
};

export default App;
