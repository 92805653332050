import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from "react";
import { Context } from "../../../../../utils/context";
import JoditEditor from "jodit-react";

import "../../Project/style/Common/Common.css";
import "../../Project/style/Modals/Modals.css";
import "../../Project/style/Form/Form.css";
import "../../Project/style/Tables/Tables.css";
import "./newtaskkk-main-sec.css";

import Select from "react-select";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Button, Card, Modal, Table } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
// import TableModals from "../../Modals/TableModals/TableModals";
import AddEmployeeInfoModal from "../../Project/Modals/AddEmployeeInfoModal/AddEmployeeInfoModal";
import AddTaskLabelModal from "../../Project/Modals/AddTaskLabelModal/AddTaskLabelModal";
import { Link } from "react-router-dom";

import InputGroup from "react-bootstrap/InputGroup";
import { Label } from "recharts";
library.className = fas;

const Newtask = (props) => {
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShow2, setModalShow2] = React.useState(false);
  const [modalShow3, setModalShow3] = React.useState(false);
  const [taskCategory, setTaskCategory] = useState([]);
  const [projects, setProjects] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [tasklabels, setTasklabels] = useState([]);
  const [taskPriority, setTaskPriority] = useState([]);
  const [days, setDays] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [estimateHrs, setEstimateHrs] = useState([]);
  const [estimateMin, setEstimateMin] = useState([]);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [showForm, setShowForm] = useState(false);

  const { postData, getData, deleteData } = useContext(Context);

  const [formData, setFormData] = useState({
    title: "",
    project_id: null,
    task_category_id: null,
    task_label_id: null,
    task_priority_id: null,
    description: "",
    label_id: null,
    start_date: null,
    due_date: null,
    make_private: "",
    billable: "",
    set_time_estimate: "",
    repeat: "",
    repeat_every: "",
    days_id: null,
    cycle: "",
    dependent_task_id: null,
    media: null,
    assign_to_id: null,
  });

  const editor = useRef(null);
  const [content, setContent] = useState("");

  const handleShowForm = () => {
    setShowForm(true);
  };

  const handleCloseForm = () => {
    setShowForm(false);
  };

  const handleCloseModal = () => {
    setModalShow3(false);
  };

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  const handleChange = (e, contentName) => {
    if (e?.name == "project_id") {
      setFormData({ ...formData, [e.name]: e });
    } else if (e?.name == "task_category_id") {
      setFormData({ ...formData, [e.name]: e });
    } else if (e?.name == "task_label_id") {
      setFormData({ ...formData, [e.name]: e });
    } else if (e?.name == "dependent_task_id") {
      setFormData({ ...formData, [e.name]: e });
    } else if (e?.name == "task_priority_id") {
      setFormData({ ...formData, [e.name]: e });
    } else if (e?.name == "days_id") {
      setFormData({ ...formData, [e.name]: e });
    } else if (e?.name == "assign_to_id") {
      setFormData({ ...formData, [e.name]: e });
    } else {
      // Handle other types of input changes
      if (e && e.target) {
        const { name, value, type, checked, files } = e.target;
        if (type === "checkbox") {
          setFormData({ ...formData, [name]: checked ? "1" : "0" });
        } else if (type === "date") {
          const dateValue = new Date(value);
          setFormData({ ...formData, [name]: dateValue });
        } else if (type === "file") {
          if (files.length > 0) {
            setFormData({ ...formData, [name]: files[0] });
          }
        } else {
          setFormData({ ...formData, [name]: value });
        }
      } else {
        setFormData({ ...formData, [contentName]: e });
      }
    }
  };

  const handleJoEditChange = (value) => {
    setFormData({ ...formData, description: value });
  };

  const handlePriorityChange = (selectedOption) => {
    // Update the formData state with the selected priority value
    setFormData({ ...formData, task_priority_id: selectedOption.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formDataToSend = new FormData();

      formDataToSend.append("title", formData.title);
      formDataToSend.append("project_id", formData.project_id?.value);
      formDataToSend.append(
        "task_category_id",
        formData.task_category_id?.value
      );
      formDataToSend.append("task_label_id", formData.task_label_id?.value);
      formDataToSend.append(
        "task_priority_id",
        parseInt(formData.task_priority_id?.value)
      );

      formDataToSend.append("description", formData.description);
      // formDataToSend.append("label_id", formData.label_id.value);
      formDataToSend.append("start_date", startDate);
      formDataToSend.append("due_date", endDate);
      formDataToSend.append("make_private", formData.make_private);
      formDataToSend.append("billable", formData.billable);
      formDataToSend.append(
        "set_time_estimate",
        `${estimateHrs} hrs ${estimateMin} mins`
      );
      formDataToSend.append("repeat", formData.repeat);
      formDataToSend.append("days_id", formData.days_id?.value);
      formDataToSend.append("repeat_every", formData.repeat_every);
      formDataToSend.append("cycle", formData.cycle);
      formDataToSend.append(
        "dependent_task_id",
        formData.dependent_task_id?.value
      );
      formDataToSend.append("assign_to_id", formData.assign_to_id?.value);
      formDataToSend.append("media", formData.media);

      console.log("Data to be sent:", Object.fromEntries(formDataToSend));

      // Make POST request using axios
      const response = await postData(
        "/projectmanagement/task",
        formDataToSend
      );

      if (response?.success) {
        console.log("Form Submited :- ", response);
      } else {
        console.error("Submission failed:", response?.error);
        // Handle submission failure
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle submission error
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    // Handle file upload logic here
    setFormData((prevFormData) => ({
      ...prevFormData,
      media: acceptedFiles[0],
    }));
    console.log(acceptedFiles);
  }, []);

  const onFileSelect = (e) => {
    const file = e.target.files[0];
    setFormData((prevFormData) => ({
      ...prevFormData,
      media: file,
    }));
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showtask, setShowTask] = useState(false);
  const handleClose1 = () => setShowTask(false);
  const handleShow2 = () => setShowTask(true);

  const [showrepeat, setShowRepeat] = useState(false);
  const handleClose3 = () => setShowRepeat(false);
  const handleShow3 = () => setShowRepeat(true);

  const [showtime, setTimeEstimate] = useState(false);
  const handleClose4 = () => setTimeEstimate(false);
  const handleShow4 = () => setTimeEstimate(true);

  const getAllProject = async () => {
    try {
      const res = await getData(`/project_management/allproject`);

      const options = [];
      if (res?.success) {
        await res?.data?.map((data) => {
          options.push({
            value: data.id,
            name: "project_id",
            label: data.name,
          });
        });
        await setProjects(options);
      }
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  const getAllTaskCategory = async () => {
    try {
      const res = await getData(`/project_management/alltaskcategory`);
      const options = [];
      if (res?.success) {
        await res?.data?.map((data) => {
          options.push({
            value: data.id,
            name: "task_category_id",
            label: data.name,
          });
        });

        await setTaskCategory(options);
      }
    } catch (error) {
      console.error("Error fetching taskCategory:", error);
    }
  };

  const getAllTask = async () => {
    try {
      const res = await getData(`/project_management/alltask`);
      const options = [];
      if (res?.success) {
        await res?.data?.map((data) => {
          options.push({
            value: data.id,
            name: "dependent_task_id",
            label: data?.title,
          });
        });
        await setTasks(options);
      }
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };

  const getAllTaslLabel = async () => {
    try {
      const res = await getData(`/project_management/alltasklabel`);
      const options = [];
      if (res?.success) {
        await res?.data?.map((data) => {
          options.push({
            value: data.id,
            name: "task_label_id",
            label: data?.name,
          });
        });
        await setTasklabels(options);
      }
    } catch (error) {
      console.error("Error fetching tasklabels:", error);
    }
  };

  const getAllTaskPriority = async () => {
    try {
      const res = await getData(`/project_management/alltaskpriority`);
      const options = [];
      if (res?.success) {
        await res?.data?.map((data) => {
          options.push({
            value: data.id,
            name: "task_priority_id",
            label: data?.name,
          });
        });
        await setTaskPriority(options);
      }
    } catch (error) {
      console.error("Error fetching tasklabels:", error);
    }
  };

  const getAllDays = async () => {
    try {
      const res = await getData(`/project_management/alldays`);
      const options = [];
      if (res?.success) {
        await res?.data?.map((data) => {
          options.push({
            value: data.id,
            name: "days_id",
            label: data?.name,
          });
        });
        await setDays(options);
      }
    } catch (error) {
      console.error("Error fetching days:", error);
    }
  };
  const getAllUsers = async () => {
    try {
      const res = await getData("/masters/allusers");
      const option = [];
      if (res.success) {
        res?.data?.map((data) => {
          option.push({
            value: data?.userid,
            name: "assign_to_id",
            label: data?.user?.name,
          });
        });
      }
      setEmployees(option);
    } catch (error) {
      console.error("Error fetching days:", error);
    }
  };

  console.log("days - :", days);
  console.log("taskPriority - :", taskPriority);
  console.log("tasklabels - :", tasklabels);
  console.log("tasks - :", tasks);
  console.log("taskCategory - :", taskCategory);
  console.log("projects - :", projects);
  console.log("employees - :", employees);

  useEffect(() => {
    getAllProject();
    getAllTaskCategory();
    getAllTask();
    getAllTaslLabel();
    getAllTaskPriority();
    getAllDays();
    getAllUsers();
  }, []);

  // ..........................................  Task Category Modal ........................................................

  const [formCategoryData, setFormCategoryData] = useState({
    name: "",
  });

  const handleTaskCAtegorySubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await postData(
        "/projectmanagement/task_categoty",
        formCategoryData
      );
      if (response.success) {
        // If deletion is successful, refresh the task categories
        await getAllTaskCategory();
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleDeleteCategory = async (catId) => {
    if (catId) {
      const response = await deleteData(
        `/projectmanagement/task_categoty/${catId}`
      );
      console.log("Record sent to be deleted :- ", catId);
      if (response.success) {
        // If deletion is successful, refresh the task categories
        await getAllTaskCategory();
      }
    }
  };

  // .................................................  END ..................................................................

  return (
    <>
      <section className="newtaskkk-main-sec Add_Project_main p-3">
        <div className="task_details_form start">
          <div>
            <div className="new_task_header card-header">
              <div className="card-title h5" id="contained-modal-title-vcenter">
                <h5 className="page-title">
                  {" "}
                  <FontAwesomeIcon icon="fa-solid fa-plus me-2" /> New Tasks
                </h5>
              </div>
            </div>

            <div className="card-body">
              <div className="field_top_space">
                <div className="row">
                  <div className="col-md-4 mb-2">
                    <Form>
                      <Form.Group controlId="Title">
                        <Form.Label className="required">Title</Form.Label>
                        <Form.Control
                          type="text"
                          name="Title"
                          placeholder="Title"
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              title: e.target.value,
                            })
                          }
                        />
                        <Form.Control type="hidden" name="Title" />
                      </Form.Group>
                    </Form>
                  </div>
                  <div className="col-md-4 mb-2">
                    <Form>
                      <Form.Group controlId="Title">
                        <Form.Label className="required">Project</Form.Label>
                        <Select
                          name="project_category_id"
                          className="input-mandatory"
                          onChange={handleChange}
                          // onChange={(selectedOption) => handleChange(selectedOption, "project_category_id")}
                          value={formData?.project_id}
                          options={projects}
                        />
                        <Form.Control type="hidden" name="Title" />
                      </Form.Group>
                    </Form>
                  </div>
                  <div className="col-md-4 mb-2">
                    <Form>
                      <Form.Group controlId="Title">
                        <Form.Label className="required">
                          Task Category{" "}
                          <button
                            type="button"
                            onClick={handleShow}
                            className="taskk-catt-bt"
                          >
                            {" "}
                            + Task Category
                          </button>{" "}
                        </Form.Label>
                        <Select
                          options={taskCategory}
                          value={formData.task_category_id}
                          onChange={handleChange}
                        />
                        <Form.Control type="hidden" name="Title" />
                      </Form.Group>
                    </Form>
                  </div>
                </div>
              </div>

              <Modal
                className="table_modal"
                size="lg"
                show={show}
                onHide={handleClose}
                animation={false}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Task Category</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <td>#</td>
                        <td>CATEGORY NAME</td>
                        <td>ACTION</td>
                      </tr>
                    </thead>
                    <tbody>
                      {taskCategory?.map((item, index) => (
                        <tr key={item.id}>
                          <td>{index + 1}</td>
                          <td>{item.label}</td>
                          <td>
                            <button
                              className="btn btn-danger"
                              onClick={() => handleDeleteCategory(item.value)}
                            >
                              Remove
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <Form>
                    <Form.Group controlId="validationCustom01">
                      <Form.Label>Category Name</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="First name"
                        defaultValue="Mark"
                        value={formData.name}
                        onChange={(e) =>
                          setFormCategoryData({
                            ...formCategoryData,
                            name: e.target.value,
                          })
                        }
                      />
                    </Form.Group>

                    <button
                      type="submit"
                      onClick={handleTaskCAtegorySubmit}
                      className="btn btn-success mt-3"
                    >
                      <FontAwesomeIcon icon="fa-solid fa-check" /> Save
                    </button>
                  </Form>
                </Modal.Body>
              </Modal>

              <div className="field_top_space">
                <div className="row">
                  <div className="col-md-12">
                    <Form>
                      <Form.Group controlId="Description">
                        <Form.Label className="">Description</Form.Label>
                      </Form.Group>
                      <JoditEditor
                        ref={editor}
                        value={content}
                        tabIndex={1} // tabIndex of textarea
                        onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                        onChange={handleJoEditChange}
                      />
                    </Form>
                  </div>
                </div>
              </div>

              <div className="field_top_space">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form>
                    <Form.Check
                      onClick={() => setShowTask(!showtask)}
                      type="checkbox"
                      label={`Task is dependent on another task`}
                    />
                  </Form>
                </Form.Group>

                {showtask && (
                  <Form>
                    <Form.Group controlId="Title">
                      <Form.Label className="">Dependent Task</Form.Label>
                      <Select
                        options={tasks}
                        name="dependent_task_id"
                        value={formData.dependent_task_id}
                        onChange={handleChange}
                      />
                      <Form.Control type="hidden" name="Title" />
                    </Form.Group>
                  </Form>
                )}
              </div>

              <div className="field_top_space">
                <Form>
                  <Form.Group controlId="Title">
                    <Form.Label className="">
                      Label{" "}
                      <button
                        type="button"
                        class="taskk-catt-bt"
                        onClick={() => setModalShow3(true)}
                      >
                        {" "}
                        + Add Task Label
                      </button>
                    </Form.Label>
                    <Select
                      options={tasklabels}
                      value={formData.task_label_id}
                      onChange={handleChange}
                    />
                    <Form.Control type="hidden" name="Title" />
                  </Form.Group>
                </Form>

                <AddTaskLabelModal
                  show={modalShow3}
                  onHide={() => setModalShow3()}
                />
              </div>

              <div className="field_top_space">
                <div className="row">
                  <div className="col-md-4">
                    <Form>
                      <Form.Group controlId="Joining_Date">
                        <Form.Label className="required">Start Date</Form.Label>
                        <div>
                          <DatePicker
                            selected={startDate}
                            defaultValue={formData.start_date}
                            onChange={(date) => setStartDate(date)}
                            name="start_date"
                            id="start_date"
                            autoComplete="off"
                            className="form-control"
                          />
                        </div>
                      </Form.Group>
                    </Form>
                  </div>

                  <div className="col-md-4">
                    <Form>
                      <Form.Group controlId="Due_Date">
                        <Form.Label className="required">Due Date</Form.Label>
                        <div>
                          <DatePicker
                            selected={endDate}
                            minDate={startDate}
                            defaultValue={formData.due_date}
                            onChange={(date) => setEndDate(date)}
                            name="end_date"
                            id="end_date"
                            autoComplete="off"
                            className="form-control"
                          />
                        </div>
                      </Form.Group>
                    </Form>
                  </div>

                  <div className="col-md-4">
                    <Form>
                      <Form.Group controlId="Due_Date">
                        <Form.Label className="required">
                          Assigned To
                        </Form.Label>
                        <button
                          onClick={() => setModalShow2(true)}
                          type="button"
                          class="taskk-catt-bt"
                        >
                          {" "}
                          +{" "}
                        </button>
                        <Select
                          options={employees}
                          value={formData.assign_to_id}
                          onChange={handleChange}
                        />
                      </Form.Group>

                      <AddEmployeeInfoModal
                        show={modalShow2}
                        onHide={() => setModalShow2(false)}
                      />
                    </Form>
                  </div>
                </div>
              </div>

              <div className="field_top_space">
                <div className="row">
                  <div className="col-lg-4 mb-3">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput2"
                    >
                      <Form>
                        <Form.Check
                          type="checkbox"
                          label="Make Private"
                          checked={formData.make_private || false}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              make_private: e.target.checked,
                            })
                          }
                        />
                      </Form>
                    </Form.Group>
                  </div>
                  <div className="col-lg-3 mb-3">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput3"
                    >
                      <Form>
                        <Form.Check
                          type="checkbox"
                          label={`Billable`}
                          checked={formData.billable || false}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              billable: e.target.checked,
                            })
                          }
                        />
                      </Form>
                    </Form.Group>
                  </div>

                  <div className="col-lg-5 mb-3">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput4"
                    >
                      <Form>
                        <div className="d-flex">
                          <div>
                            <Form.Check
                              onClick={() => setTimeEstimate(!showtime)}
                              type="checkbox"
                              label={`Set time estimate`}
                            />
                          </div>
                          <div>
                            {showtime && (
                              <div className="d-flex settimee-inp my-auto">
                                <Form.Control
                                  type="number"
                                  name="hours" // Name it as 'hours' to differentiate from minutes
                                  onChange={(e) =>
                                    setEstimateHrs(e.target.value)
                                  } // Update estimateHrs directly with the value
                                />
                                <Form.Label className="">hrs</Form.Label>
                                <Form.Control
                                  type="number"
                                  name="minutes" // Name it as 'minutes'
                                  onChange={(e) =>
                                    setEstimateMin(e.target.value)
                                  } // Update estimateMin directly with the value
                                />
                                <Form.Label className="">mins</Form.Label>
                              </div>
                            )}
                          </div>
                        </div>
                      </Form>
                    </Form.Group>
                  </div>

                  <div className="col-lg-3 mb-3">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput5"
                    >
                      <Form>
                        <Form.Check
                          onClick={() => setShowRepeat(!showrepeat)}
                          type="checkbox"
                          label={`Repeat`}
                          checked={formData.repeat || false}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              repeat: e.target.checked,
                            })
                          }
                        />
                      </Form>
                    </Form.Group>
                  </div>

                  {showrepeat && (
                    <div className="col-lg-9">
                      <div className="row">
                        <div className="col-lg-4 mb-3">
                          <Form>
                            <Form.Group controlId="Title">
                              <Form.Label className="">Repeat every</Form.Label>
                              <Form.Control
                                type="number"
                                name="Title"
                                value={formData.repeat_every}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    repeat_every: e.target.value,
                                  })
                                }
                              />
                            </Form.Group>
                          </Form>
                        </div>

                        <div className="col-lg-4 mb-3">
                          <Form>
                            <Form.Group controlId="Title">
                              <Form.Label className="">Select</Form.Label>
                              <Select
                                options={days}
                                value={formData.days_id}
                                onChange={handleChange}
                              />

                              <Form.Control type="hidden" name="Title" />
                            </Form.Group>
                          </Form>
                        </div>

                        <div className="col-lg-4 mb-3">
                          <Form>
                            <Form.Group controlId="Title">
                              <Form.Label className="">Cycles</Form.Label>
                              <Form.Control
                                type="number"
                                name="Title"
                                value={formData.cycle}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    cycle: e.target.value,
                                  })
                                }
                              />
                            </Form.Group>
                          </Form>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* <div className="field_top_space">
                                <div className="row">
                                    <div class="col-md-12">
                                        <Form.Group className="form-group">
                                            <Form.Label className="control-label">
                                                Priority
                                            </Form.Label>

                                            <div>
                                                <Form.Check
                                                    type="radio"
                                                    name="priority"
                                                    id="radio13"
                                                    value="high"
                                                    label="High"
                                                    className="text-danger radio radio-danger me-2"
                                                    onChange={handlePriorityChange}
                                                />
                                                <Form.Check
                                                    type="radio"
                                                    name="priority"
                                                    id="radio14"
                                                    value="medium"
                                                    label="Medium"
                                                    defaultChecked
                                                    className="text-warning radio radio-warning me-2"
                                                    onChange={handlePriorityChange}
                                                />
                                                <Form.Check
                                                    type="radio"
                                                    name="priority"
                                                    id="radio15"
                                                    value="low"
                                                    label="Low"
                                                    className="text-success radio radio-success me-2"
                                                    onChange={handlePriorityChange}
                                                />
                                            </div>
                                        </Form.Group>
                                    </div>
                                </div>
                            </div> */}

              <div className="col-md-12">
                <Form.Group className="form-group">
                  <Form.Label className="control-label">Priority</Form.Label>
                  <div>
                    {/* {taskPriority?.map(priority => (
                                            <Form.Check
                                                key={priority.value}
                                                type="radio"
                                                name="priority"
                                                id={`radio${priority.value}`}
                                                value={priority.value}
                                                label={priority.label}
                                                className={`text-${priority.label.toLowerCase()} radio radio-${priority.label.toLowerCase()} me-2`}
                                                onChange={() => handlePriorityChange(priority)}
                                                checked={formData.task_priority_id === priority.value}
                                            />
                                        ))} */}
                    <Select
                      options={taskPriority}
                      value={formData.task_priority_id}
                      onChange={handleChange}
                    />
                  </div>
                </Form.Group>
              </div>

              <div className="field_top_space" id="file-upload-box">
                <div className="row" id="file-dropzone">
                  <div className="col-md-12">
                    <Form.Group>
                      <div
                        {...getRootProps()}
                        className={`dropzone ${isDragActive ? "active" : ""}`}
                      >
                        {/* Hidden input field for file upload */}
                        <Form.Control
                          {...getInputProps()}
                          onChange={onFileSelect}
                          name="file" // Ensure the name attribute is "file"
                          id="file" // Ensure the ID attribute is "file"
                          type="file" // Use type "file" for file input
                          style={{ display: "none" }} // Hide the input field
                        />
                        <div className="dz-default dz-message">
                          <span>Drop files here to upload</span>
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                </div>
              </div>

              <Form>
                <Link to="/project-management/tasks">
                  <Button
                    className="btn btn-success save-btn my-2"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    <FontAwesomeIcon icon="fa-solid fa-check" /> Save
                  </Button>
                </Link>
              </Form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Newtask;
