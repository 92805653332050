import React, { useState } from "react";
import "../../style/Modals/Modals.css";
import "../../style/Common/Common.css";
import "../../style/Form/Form.css";
import "../../style/Tables/Tables.css";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Form } from "react-bootstrap";
import { Button, Table } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);

function MilestonesDetails(props) {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  return (
    <>
      <Modal
        {...props}
        size="lg"
        className="table_modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Milestones Details
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="details">
            <div className="field_top_space">
              <div className="row">
                <div className="col-md-12">
                  <Form>
                    <Form.Group controlId="Milestone_Title">
                      <Form.Label className="">Milestone Title</Form.Label>
                      <Form.Control
                        type="text"
                        name="demo"
                        placeholder="Enter Milestone Title"
                      />
                      <Form.Control type="hidden" name="Milestone_Title" />
                    </Form.Group>
                  </Form>
                </div>
              </div>
            </div>

            <div className="field_top_space">
              <div className="row">
                <div className="col-md-12">
                  <Form>
                    <Form.Group controlId="Milestone Summary">
                      <Form.Label className="">Milestone Summary</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="Milestone_Summary"
                        rows={3}
                        placeholder="Enter Milestone Summary"
                      />
                      <Form.Control type="hidden" name="Milestone_Summary" />
                    </Form.Group>
                  </Form>
                </div>
              </div>
            </div>

            <div className="field_top_space">
              <div className="row">
                <div className="col-md-6">
                  <Form>
                    <Form.Group controlId="Milestone_Cost">
                      <Form.Label className="">Milestone Cost</Form.Label>
                      <Form.Control
                        type="text"
                        name="Milestone_Cost"
                        placeholder="₹0.00"
                      />
                      <Form.Control type="hidden" name="Milestone_Cost" />
                    </Form.Group>
                  </Form>
                </div>

                <div className="col-md-6">
                  <Form>
                    <Form.Group controlId="Status">
                      <Form.Label className="">Status</Form.Label>
                      <Form.Control
                        type="text"
                        name="Milestone_Summary"
                        placeholder="Enter Status"
                      />
                      <Form.Control type="hidden" name="Status" />
                    </Form.Group>
                  </Form>
                </div>
              </div>
            </div>
          </div>

          <div className="details_table">
            <h6 className="page-title">Task</h6>
            <Table responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Task</th>
                  <th>Assigned To</th>
                  <th>Assigned By</th>
                  <th>Due Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Demo</td>
                  <td>XYZ</td>
                  <td>ABC</td>
                  <td>13-02-2024</td>
                  <td>
                    <span className="label label-danger">Incomplete</span>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>

          <Form>
            <Button className="btn btn-secondary save-btn my-2" type="submit">
              Close
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default MilestonesDetails;
