import React, { useState } from "react";
import "../../style/Form/Form.css";
import "../../style/Common/Common.css";
import "../../style/Tables/Tables.css";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Button, Pagination, Table } from "react-bootstrap";
import { TimePicker } from "antd";
import DeleteModal from "../../Modals/DeleteModal/DeleteModal";
import UpdateTimeLogModal from "../../Modals/UpdateTimeLogModal/UpdateTimeLogModal";

library.add(fas);

function TimeLog() {
  const [modalShow, setModalShow] = React.useState(false);
  const [deleteModalShow, setDeleteModalShow] = React.useState(false);
  const [showForm, setShowForm] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const toggleFormVisibility = () => {
    setShowForm(!showForm);
  };
  return (
    <>
      {/* Milestone tab content start (smita) */}
      <div className="Milestone_tab_content">
        {/* Milestone form start (smita ) */}
        <div className="create_milestone_btn">
          <h6 className="Page-title">Time Log</h6>
          <div className="row">
            <div className="col-md-12">
              <Button
                className="btn btn-success save-btn my-2"
                type="button" // Change type to "button" to prevent form submission
                onClick={toggleFormVisibility}
              >
                <FontAwesomeIcon className="me-2" icon="fa-solid fa-clock " />
                Time Log
              </Button>
            </div>
          </div>
        </div>

        {showForm && (
          <div className="create_milestone_form">
            <div className="row">
              <div className="col-md-12">
                <Form>
                  <div className="form-body">
                    <div className="field_top_space">
                      <div className="row">
                        <div className="col-md-3">
                          <Form.Group controlId="status">
                            <Form.Label>Task</Form.Label>
                            <Form.Select as="select">
                              <option value="No_task">
                                No Task Added To The Project
                              </option>
                              <option value="nill">--</option>
                            </Form.Select>
                          </Form.Group>
                        </div>
                        <div className="col-md-3">
                          <Form.Group controlId="status">
                            <Form.Label>Employee Name</Form.Label>
                            <Form.Select as="select">
                              <option value="Smita_Lad">Smita_Lad</option>
                              <option value="Other">Other</option>
                            </Form.Select>
                          </Form.Group>
                        </div>

                        <div className="col-md-3">
                          <Form>
                            <Form.Group controlId="Start_Date">
                              <Form.Label className="">Start Date</Form.Label>
                              <div>
                                <DatePicker
                                  selected={startDate}
                                  onChange={(date) => setStartDate(date)}
                                  name="start_date"
                                  id="start_date"
                                  autoComplete="off"
                                  className="form-control"
                                />
                              </div>
                            </Form.Group>
                          </Form>
                        </div>

                        <div className="col-md-3">
                          <Form>
                            <Form.Group controlId="End_Date">
                              <Form.Label className="">End Date</Form.Label>
                              <div>
                                <DatePicker
                                  selected={endDate}
                                  onChange={(date) => setEndDate(date)}
                                  name="end_date"
                                  id="end_date"
                                  autoComplete="off"
                                  className="form-control"
                                />
                              </div>
                            </Form.Group>
                          </Form>
                        </div>
                      </div>
                    </div>

                    <div className="field_top_space">
                      <div className="row">
                        <div className="col-md-4">
                          <Form>
                            <Form.Group controlId="Start_Time">
                              <Form.Label className="">Start Time</Form.Label>
                              <div>
                                <TimePicker
                                  name="start_time"
                                  id="start_time"
                                  autoComplete="off"
                                  className="form-control"
                                />
                              </div>
                            </Form.Group>
                          </Form>
                        </div>

                        <div className="col-md-4">
                          <Form>
                            <Form.Group controlId="End_Time">
                              <Form.Label className="">End Time</Form.Label>
                              <div>
                                <TimePicker
                                  name="end_time"
                                  id="end_time"
                                  autoComplete="off"
                                  className="form-control"
                                />
                              </div>
                            </Form.Group>
                          </Form>
                        </div>

                        <div className="col-md-4">
                          <Form>
                            <Form.Group controlId="End_Time">
                              <Form.Label className="">Total Hours</Form.Label>
                              <Form.Control
                                type="text"
                                name="Total Hours"
                                placeholder="0Hrs 0Mins"
                              />
                            </Form.Group>
                          </Form>
                        </div>
                      </div>
                    </div>

                    <div className="field_top_space">
                      <div className="row">
                        <div className="col-md-6">
                          <Form.Group controlId="Memo">
                            <Form.Label>Memo</Form.Label>
                            <Form.Control type="text" defaultValue="" />
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-actions mt-3">
                    <Form>
                      <Button
                        className="btn btn-success save-btn my-2 me-2"
                        type=""
                      >
                        <FontAwesomeIcon icon="fa-solid fa-check" /> Save
                      </Button>
                    </Form>
                  </div>
                </Form>

                <hr />
              </div>
            </div>
          </div>
        )}

        {/* Milestone form end (smita ) */}

        {/* Milestone table start  (smita) */}
        <div className="Milestone_table mt-5">
          <div className="row justify-content-between">
            <div className="col-md-2">
              <div className="dataTables_length " id="timelog-table_length">
                <Form.Label className="flex-box">
                  <p className="xl-text mb-0 me-1">Show</p>
                  <Form.Select
                    as="select"
                    name="timelog-table_length"
                    aria-controls="timelog-table"
                    className="form-control input-sm"
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </Form.Select>{" "}
                  <p className="xl-text mb-0 ms-1">entries</p>
                </Form.Label>
              </div>
            </div>
            <div className="col-md-3">
              <div id="timelog-table_filter" className="dataTables_filter ">
                <Form.Label className="flex-box">
                  <p className="xl-text mb-0 me-2">Search:</p>
                  <Form.Control
                    type="search"
                    className="form-control input-sm"
                    placeholder=""
                    aria-controls="timelog-table"
                  />
                </Form.Label>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-12">
              <Table
                bordered
                hover
                responsive="md"
                id="timelog-table"
                className="toggle-circle default footable-loaded footable dataTable no-footer dtr-inline"
                role="grid"
                aria-describedby="timelog-table_info"
              >
                <thead>
                  <tr role="row">
                    <th className="sorting_desc">Id</th>
                    <th className="sorting_desc">Who Logged</th>
                    <th className="sorting">Start Time</th>
                    <th className="sorting">End Time</th>
                    <th className="sorting">Total Hours</th>
                    <th className="sorting">Memo</th>
                    <th className="sorting">Last updated by</th>
                    <th className="sorting">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="odd">
                    <td valign="top" className="dataTables_empty">
                      1236
                    </td>
                    <td valign="top" className="dataTables_empty">
                      Shubham Yadav
                    </td>
                    <td valign="top" className="dataTables_empty">
                      12-02-2024 10:49 AM
                    </td>
                    <td valign="top" className="dataTables_empty">
                      12-02-2024 11:58 AM
                    </td>
                    <td valign="top" className="dataTables_empty">
                      1 hrs 8 mins
                    </td>
                    <td valign="top" className="dataTables_empty">
                      Coming Soon Video Creation
                    </td>
                    <td valign="top" className="dataTables_empty">
                      Shubham Yadav
                    </td>
                    <td>
                      <Button
                        variant="info"
                        size="sm"
                        className="btn-outline edit-members me-2"
                        onClick={() => setModalShow(true)}
                      >
                        <FontAwesomeIcon icon="fa-solid fa-pen edit" />
                      </Button>
                      <UpdateTimeLogModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                      />
                      <Button
                        variant="danger"
                        size="sm"
                        className="btn-outline delete-members "
                        onClick={() => setDeleteModalShow(true)}
                      >
                        <FontAwesomeIcon icon="fa-solid fa-trash delete" />
                      </Button>
                      <DeleteModal
                        show={deleteModalShow}
                        onHide={() => setDeleteModalShow(false)}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>

          <div className="row justify-content-between">
            <div className="col-md-6">
              <div
                className="dataTables_info"
                id="timelog-table_info"
                role="status"
                aria-live="polite"
              >
                <p className="sm-text">Showing 0 to 0 of 0 entries</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="Pagination_end">
                <nav aria-label="..." className="templateNav">
                  <Pagination>
                    <Pagination.Prev className="sm-text">
                      Previous
                    </Pagination.Prev>
                    <Pagination.Item className="sm-text">1</Pagination.Item>
                    <Pagination.Item className="sm-text" active>
                      2
                    </Pagination.Item>
                    <Pagination.Item className="sm-text">3</Pagination.Item>
                    <Pagination.Next className="sm-text"> Next</Pagination.Next>
                  </Pagination>
                </nav>
              </div>
            </div>
          </div>
        </div>
        {/* Milestone table end (smita) */}
      </div>
      {/* Milestone tab content end (smita) */}
    </>
  );
}

export default TimeLog;
