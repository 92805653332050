import React from "react";
import "./Discussion.css";
import "../../style/Form/Form.css";
import "../../style/Common/Common.css";
import "../../style/Tables/Tables.css";
import Form from "react-bootstrap/Form";
import { Button, Pagination, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import NewDiscussionModal from "../../Modals/NewDiscussionModal/NewDiscussionModal";
library.className = fas;

function Discussion() {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
      <div className="discussion">
        <div class="row ">
          <div class="col-md-3 ">
            <div class="row" id="button-area">
              <div class="col-md-12">
                <Button
                  className="btn btn-info save-btn my-2"
                  onClick={() => setModalShow(true)}
                >
                  <FontAwesomeIcon icon="fa-solid fa-plus" /> New Discussion
                </Button>

                <NewDiscussionModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                      />

                
              </div>
            </div>

            <div class="row mt-4" id="categories-area">
              <div class="col-md-12">
                <ul class="discussion-categories">
                  <li class="active">
                    <p className="text-dark xl-text mb-0">
                      <FontAwesomeIcon
                        className="text-dark xs-text me-2"
                        icon="fa-solid fa-circle"
                      />{" "}
                      All Discussion
                    </p>
                  </li>

                  <li>
                    <p className="text-success xl-text mb-0">
                      {" "}
                      <FontAwesomeIcon
                        className="text-success xs-text me-2"
                        icon="fa-solid fa-circle"
                      />
                      Logins
                    </p>
                  </li>
                  <li>
                    <p className="text-primary xl-text mb-0">
                      {" "}
                      <FontAwesomeIcon
                        className="text-primary xs-text me-2"
                        icon="fa-solid fa-circle"
                      />
                      Other Discussion
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col-md-9">
            <div class="row mt-3">
              <div class="col-md-12">
                <div className="row justify-content-between">
                  <div className="col-md-2">
                    <div
                      className="dataTables_length "
                      id="timelog-table_length"
                    >
                      <Form.Label className="flex-box">
                        <p className="xl-text mb-0 me-1">Show</p>
                        <Form.Select
                          as="select"
                          name="timelog-table_length"
                          aria-controls="timelog-table"
                          className="form-control input-sm"
                        >
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </Form.Select>{" "}
                        <p className="xl-text mb-0 ms-1 ms-1 ">entries</p>
                      </Form.Label>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div
                      id="timelog-table_filter"
                      className="dataTables_filter "
                    >
                      <Form.Label className="flex-box">
                        <p className="xl-text mb-0 me-1">Search:</p>
                        <Form.Control
                          type="search"
                          className="form-control input-sm"
                          placeholder=""
                          aria-controls="timelog-table"
                        />
                      </Form.Label>
                    </div>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-md-12">
                    <Table
                      bordered
                      hover
                      responsive="md"
                      className="toggle-circle default footable-loaded footable dataTable no-footer dtr-inline"
                      id="tasks-table"
                      role="grid"
                      aria-describedby="tasks-table_info"
                    >
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Message</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="odd">
                          <td>
                            <div className="row">
                              <div className="col-sm-3 col-xs-4">
                                <img
                                  src="https://teampro.profcymaglobal.com/img/default-profile-3.png"
                                  alt="user"
                                  className="img-circle"
                                  width="40"
                                  height="40"
                                />
                              </div>
                              <div className="col-sm-9 col-xs-8">
                                <p className="xl-text">Other</p>
                                <span className="text-muted lg-text">
                                  Smita Madan Lad posted on 09-02-2024 05:19 PM
                                </span>
                              </div>
                            </div>
                          </td>

                          <td>
                            <p className="xl-text">
                              {" "}
                              <FontAwesomeIcon
                                className="text-dark xl-text me-2"
                                icon="fa-solid fa-comment"
                              />
                              1
                            </p>
                          </td>

                          <td>
                            <p className="text-warning xs-text">
                              <FontAwesomeIcon
                                className="text-warning xs-text me-2"
                                icon="fa-solid fa-circle "
                              />
                              Logins
                            </p>
                            <p className="text-danger xs-text">
                              <FontAwesomeIcon
                                className="text-danger xs-text me-2"
                                icon="fa-solid fa-trash "
                              />
                              Delete
                            </p>
                          </td>
                        </tr>

                        <tr className="odd">
                          <td>
                            <div className="row">
                              <div className="col-sm-3 col-xs-4">
                                <img
                                  src="https://teampro.profcymaglobal.com/img/default-profile-3.png"
                                  alt="user"
                                  className="img-circle"
                                  width="40"
                                  height="40"
                                />
                              </div>
                              <div className="col-sm-9 col-xs-8">
                                <p className="xl-text">Other</p>
                                <span className="text-muted lg-text">
                                  Smita Madan Lad posted on 09-02-2024 05:19 PM
                                </span>
                              </div>
                            </div>
                          </td>

                          <td>
                            <p className="xl-text">
                              {" "}
                              <FontAwesomeIcon
                                className="text-dark xl-text me-2"
                                icon="fa-solid fa-comment"
                              />
                              1
                            </p>
                          </td>

                          <td>
                            <p className="text-primary xs-text">
                              <FontAwesomeIcon
                                className="text-primary xs-text me-2"
                                icon="fa-solid fa-circle "
                              />
                              All Discussion
                            </p>
                            <p className="text-danger xs-text">
                              <FontAwesomeIcon
                                className="text-danger xs-text me-2"
                                icon="fa-solid fa-trash "
                              />
                              Delete
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </div>

                <div className="row justify-content-between">
                  <div className="col-md-6">
                    <div
                      className="dataTables_info"
                      id="timelog-table_info"
                      role="status"
                      aria-live="polite"
                    >
                      <p className="sm-text">Showing 0 to 0 of 0 entries</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="Pagination_end">
                      <nav aria-label="..." className="templateNav">
                      <Pagination>
                          <Pagination.Prev className="sm-text">Previous</Pagination.Prev>
                          <Pagination.Item className="sm-text" >1</Pagination.Item>
                          <Pagination.Item className="sm-text" active>2</Pagination.Item>
                          <Pagination.Item className="sm-text">3</Pagination.Item>
                          <Pagination.Next className="sm-text"> Next</Pagination.Next>
                        </Pagination>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Discussion;
