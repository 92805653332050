import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import plus from "../../../../Components/Admin/assets/icons/a1.png";
import colunms from "../../../../Components/Admin/assets/icons/LINES.png";
import pen from "../../../../Components/Admin/assets/icons/pen.png";
import basket from "../../../../Components/Admin/assets/icons/basket.png";
import search1 from "../../../../Components/Admin/assets/icons/search.png";
import top from "../../../../Components/Admin/assets/icons/top.png";
import Table from "react-bootstrap/Table";
import circle from "../../assets/icons/circle.png";
import rigth from "../../assets/icons/rigth.png";
import save from "../../assets/icons/save.png";
import cancel from "../../assets/icons/cross.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../../Header/Header";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Offcanvas from "react-bootstrap/Offcanvas";

import toast, { Toaster } from "react-hot-toast";
import Card from "react-bootstrap/Card";
import Select from "react-select";
import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { formToJSON } from "axios";
import { postData } from "../../../../utils/api";
library.add(fas);

// ***********************************************************Add**********************************************************

const AddOffCanvance = (props) => {
  const { postData, getDownloadDataExcel } = useContext(Context);
  const [errors, setErrors] = useState({});
  const [file, setFile] = useState(null);

  const [resstatus, setResStatus] = useState(true);
  const [data, setdata] = useState({});

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };
  const validateForm = () => {
    let errors = {};

    // const regex =/(\.xlsx)$/i;
    var allowedExtensions = /(\.xlsx|\.csv)$/i;

    if (file === null) {
      errors.file = "File is required";
    } else if (!allowedExtensions.exec(file.name)) {
      errors.file = "Enter Valid File";
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const formData = new FormData();
        formData.append("file", file);
        const response = await postData(
          "/lead_management/leads/bulkupload",
          formData,
          {}
        );

        if (response.success) {
          setResStatus(false);

          setdata(response?.data);
          // setShowModal(true);
          // setTimeout(() => {
          //   setShowModal(false);
          //   props.handleClose();
          // }, 1000);
        } else {
          // setShowErrorModal(true);
          // setTimeout(() => {
          //   setShowErrorModal(false);
          //   props.handleClose();
          // }, 1000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const errorStyle = {
    color: "red",
    marginLeft: "5px",
  };

  const DemoDownload = async () => {
    await getDownloadDataExcel(
      "/lead_management/leads/leadbulkdownload",
      null,
      "Lead Bulk Demo"
    );
  };

  if (resstatus) {
    return (
      <>
        <Offcanvas
          show={props.show}
          style={{ width: "80%", height: "80%", margin: "auto" }}
          placement={"top"}
          onHide={props.handleClose}
        >
          <Offcanvas.Header closeButton></Offcanvas.Header>
          <Offcanvas.Body>
            <Card className="shadow-lg p-3 mb-5 bg-white rounded">
              <Card.Body>
                <Card.Title>Lead Bulk Upload</Card.Title>
                <hr />
                <Container>
                  <Row>
                    <Col md={12}>
                      <Container>
                        <Row className="">
                          <Col xxl={12} xl={12} md={10}>
                            <Form onSubmit={handleSubmit} role="form">
                              <Row>
                                <Col
                                  lg={8}
                                  md={10}
                                  className=" mx-auto Add-content"
                                >
                                  <Row>
                                    <Col md={12}>
                                      <div className="main-form-section mt-5">
                                        <Row className="justify-content-center">
                                          <Form.Label column sm={3}>
                                            Lead Bulk Upload
                                          </Form.Label>
                                          <Col sm={6}>
                                            <Form.Group>
                                              <InputGroup>
                                                <Form.Control
                                                  type="file"
                                                  accept=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                                  onChange={handleFileChange}
                                                  placeholder="Lead"
                                                  id="inputEmail3"
                                                />
                                              </InputGroup>
                                              {errors.file && (
                                                <span style={errorStyle}>
                                                  {errors.file}
                                                </span>
                                              )}
                                            </Form.Group>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                  </Row>

                                  <Row className="mt-5 pb-3">
                                    <div className="d-flex justify-content-center">
                                      <Button
                                        onClick={DemoDownload}
                                        type="button"
                                        variant="danger"
                                        className="btn btn-cancel me-2"
                                      >
                                        <FontAwesomeIcon
                                          icon="fa-solid fa-download"
                                          className="cancel-img"
                                        />
                                        Download Sample
                                      </Button>
                                      <Link>
                                        <Button
                                          onClick={props.handleClose}
                                          type="button"
                                          variant="danger"
                                          className="btn btn-cancel me-2"
                                        >
                                          <img
                                            src={cancel}
                                            className="cancel-img"
                                            alt=""
                                          />{" "}
                                          Cancel
                                        </Button>
                                      </Link>
                                      <Button
                                        variant="success"
                                        type="submit"
                                        onClick={handleSubmit}
                                        className="btn btn-save"
                                      >
                                        <img
                                          src={save}
                                          className="save-img me-2"
                                          alt=""
                                        />{" "}
                                        Save
                                      </Button>
                                    </div>
                                  </Row>
                                </Col>
                              </Row>
                            </Form>
                          </Col>
                        </Row>
                      </Container>
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
            </Card>
          </Offcanvas.Body>
        </Offcanvas>
      </>
    );
  }

  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%", height: "80%", margin: "auto" }}
        placement={"top"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded">
            <Card.Body>
              <Card.Title>Import Report</Card.Title>
              <hr />
              <Card className="mb-3">
                {/* <Card.Body> */}
                <Table
                  striped
                  bordered
                  hover
                  center
                  size="sm"
                  className="text-center m-0"
                >
                  <tbody>
                    <tr>
                      <td className="p-4">Total Lead : {data?.TotalLead}</td>
                      <td className="p-4">Lead Added : {data?.LeadAdded}</td>
                      <td className="p-4">
                        Lead Not Added : {data?.LeadNotAdded}
                      </td>
                    </tr>
                  </tbody>
                </Table>
                {/* </Card.Body> */}
              </Card>

              <Card className="mb-3">
                <Card.Header as="h5">Lead Added List</Card.Header>
                <Card.Body>
                  <Table
                    striped
                    bordered
                    hover
                    size="sm"
                    className="text-center"
                  >
                    <thead>
                      <tr>
                        <th>Sr No.</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Email</th>
                        <th>Contact</th>
                        <th>Added By</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.DataLeadAdded?.map((data, index) => (
                        <tr key={index}>
                          <td>{++index}</td>
                          <td>{data?.f_name}</td>
                          <td>{data?.l_name}</td>
                          <td>{data?.email}</td>
                          <td>{data?.mobile_no}</td>
                          <td>{data?.user}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>

              <Card>
                <Card.Header as="h5">Lead Not Added List</Card.Header>
                <Card.Body>
                  <Table
                    striped
                    bordered
                    hover
                    size="sm"
                    className="text-center"
                  >
                    <thead>
                      <tr>
                        <th>Sr No.</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Email</th>
                        <th>Contact</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.DataLeadNotAdded?.map((data, index) => (
                        <tr key={index}>
                          <td>{++index}</td>
                          <td>{data?.f_name}</td>
                          <td>{data?.l_name}</td>
                          <td>{data?.email}</td>
                          <td>{data?.mobile_no}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

// ****************************************************************edit***********************************************************

const EditOffCanvance = (props) => {
  const { postData, getData } = useContext(Context);

  const id = props.show;
  const [data, setData] = useState();

  const [formData, setFormData] = useState({
    name: "",
  });

  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const validateForm = () => {
    let errors = {};

    // const regex = /^[A-Za-z]+(\s[A-Za-z]+)*$/;
    const regex = /^[A-Za-z0-9]+(\s[A-Za-z0-9]+)*$/;

    if (!formData.name.trim()) {
      errors.name = " Name is required";
    } else if (!regex.test(formData.name.trim())) {
      errors.name = "Enter Valid name ";
    }

    return errors;
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const response = await postData(
          `/lead_management/leads/${id}`,
          formData
        );

        if (response.success) {
          setShowModal(true);
          setTimeout(() => {
            setShowModal(false);
            props.handleClose();
          }, 1000);
        } else {
          setShowErrorModal(true);
          setTimeout(() => {
            setShowErrorModal(false);
            props.handleClose();
          }, 1000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const GetEditData = async () => {
    const response = await getData(`/lead_management/leads/${id}`);
    setData(response);
    setFormData(response?.data);
  };

  useEffect(() => {
    GetEditData();
  }, []);

  const errorStyle = {
    color: "red",
    marginLeft: "5px",
  };

  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%", height: "80%", margin: "auto" }}
        placement={"top"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded">
            <Card.Body>
              <Card.Title>Lead Details</Card.Title>
              {/* <hr /> */}
              <Tabs className="mt-3">
                <TabList>
                  <Tab>
                    <b>Personal Contact</b>
                  </Tab>
                  <Tab>
                    <b>Requirement</b>
                  </Tab>
                  <Tab>
                    <b>Service</b>
                  </Tab>
                  <Tab>
                    <b>Action and Deposition</b>
                  </Tab>
                </TabList>

                <TabPanel>
                  <Row>
                    <Col lg={12} md={12} className=" mx-auto Add-content">
                      <Row>
                        <Col md={4}>
                          <div className="main-form-section mt-5">
                            <Row className="justify-content-center">
                              <Form.Label column sm={3}>
                                First Name
                              </Form.Label>
                              <Col sm={9}>
                                <Form.Group>
                                  <InputGroup>
                                    <Form.Control
                                      type="text"
                                      name="name"
                                      disabled
                                      value={formData?.f_name}
                                      onChange={handleChange}
                                      id="inputEmail3"
                                    />
                                  </InputGroup>
                                </Form.Group>
                              </Col>
                            </Row>
                          </div>
                        </Col>

                        <Col md={4}>
                          <div className="main-form-section mt-5">
                            <Row className="justify-content-center">
                              <Form.Label column sm={3}>
                                Middle Name
                              </Form.Label>
                              <Col sm={9}>
                                <Form.Group>
                                  <InputGroup>
                                    <Form.Control
                                      type="text"
                                      name="name"
                                      disabled
                                      value={formData?.m_name}
                                      onChange={handleChange}
                                      placeholder=""
                                      id="inputEmail3"
                                    />
                                  </InputGroup>
                                </Form.Group>
                              </Col>
                            </Row>
                          </div>
                        </Col>

                        <Col md={4}>
                          <div className="main-form-section mt-5">
                            <Row className="justify-content-center">
                              <Form.Label column sm={3}>
                                Last Name
                              </Form.Label>
                              <Col sm={9}>
                                <Form.Group>
                                  <InputGroup>
                                    <Form.Control
                                      type="text"
                                      name="name"
                                      disabled
                                      value={formData?.l_name}
                                      onChange={handleChange}
                                      placeholder=""
                                      id="inputEmail3"
                                    />
                                  </InputGroup>
                                </Form.Group>
                              </Col>
                            </Row>
                          </div>
                        </Col>

                        <Col md={4}>
                          <div className="main-form-section mt-5">
                            <Row className="justify-content-center">
                              <Form.Label column sm={3}>
                                Gender
                              </Form.Label>
                              <Col sm={9}>
                                <Form.Group>
                                  <InputGroup>
                                    <Form.Control
                                      type="text"
                                      name="name"
                                      disabled
                                      value={formData?.gender}
                                      onChange={handleChange}
                                      placeholder=""
                                      id="inputEmail3"
                                    />
                                  </InputGroup>
                                </Form.Group>
                              </Col>
                            </Row>
                          </div>
                        </Col>

                        <Col md={4}>
                          <div className="main-form-section mt-5">
                            <Row className="justify-content-center">
                              <Form.Label column sm={3}>
                                Contact 1
                              </Form.Label>
                              <Col sm={9}>
                                <Form.Group>
                                  <InputGroup>
                                    <Form.Control
                                      type="text"
                                      name="name"
                                      disabled
                                      value={formData?.mobile_no}
                                      onChange={handleChange}
                                      placeholder=""
                                      id="inputEmail3"
                                    />
                                  </InputGroup>
                                </Form.Group>
                              </Col>
                            </Row>
                          </div>
                        </Col>

                        <Col md={4}>
                          <div className="main-form-section mt-5">
                            <Row className="justify-content-center">
                              <Form.Label column sm={3}>
                                Contact 2
                              </Form.Label>
                              <Col sm={9}>
                                <Form.Group>
                                  <InputGroup>
                                    <Form.Control
                                      type="text"
                                      name="name"
                                      disabled
                                      value={formData?.alt_mobile_no}
                                      onChange={handleChange}
                                      placeholder=""
                                      id="inputEmail3"
                                    />
                                  </InputGroup>
                                </Form.Group>
                              </Col>
                            </Row>
                          </div>
                        </Col>

                        <Col md={4}>
                          <div className="main-form-section mt-5">
                            <Row className="justify-content-center">
                              <Form.Label column sm={3}>
                                Email
                              </Form.Label>
                              <Col sm={9}>
                                <Form.Group>
                                  <InputGroup>
                                    <Form.Control
                                      type="text"
                                      name="name"
                                      disabled
                                      value={formData?.email}
                                      onChange={handleChange}
                                      placeholder=""
                                      id="inputEmail3"
                                    />
                                  </InputGroup>
                                </Form.Group>
                              </Col>
                            </Row>
                          </div>
                        </Col>

                        <Col md={4}>
                          <div className="main-form-section mt-5">
                            <Row className="justify-content-center">
                              <Form.Label column sm={3}>
                                Email (optional)
                              </Form.Label>
                              <Col sm={9}>
                                <Form.Group>
                                  <InputGroup>
                                    <Form.Control
                                      type="text"
                                      name="name"
                                      disabled
                                      value={formData?.alt_email}
                                      onChange={handleChange}
                                      placeholder=""
                                      id="inputEmail3"
                                    />
                                  </InputGroup>
                                </Form.Group>
                              </Col>
                            </Row>
                          </div>
                        </Col>

                        <Col md={4}>
                          <div className="main-form-section mt-5">
                            <Row className="justify-content-center">
                              <Form.Label column sm={3}>
                                Date of Birth
                              </Form.Label>
                              <Col sm={9}>
                                <Form.Group>
                                  <InputGroup>
                                    <Form.Control
                                      type="text"
                                      name="name"
                                      disabled
                                      value={formData?.dob?.split("T")[0]}
                                      onChange={handleChange}
                                      placeholder=""
                                      id="inputEmail3"
                                    />
                                  </InputGroup>
                                </Form.Group>
                              </Col>
                            </Row>
                          </div>
                        </Col>

                        <Col md={4}>
                          <div className="main-form-section mt-5">
                            <Row className="justify-content-center">
                              <Form.Label column sm={3}>
                                Country
                              </Form.Label>
                              <Col sm={9}>
                                <Form.Group>
                                  <InputGroup>
                                    <Form.Control
                                      type="text"
                                      name="name"
                                      disabled
                                      value={formData?.country?.name}
                                      onChange={handleChange}
                                      placeholder=""
                                      id="inputEmail3"
                                    />
                                  </InputGroup>
                                </Form.Group>
                              </Col>
                            </Row>
                          </div>
                        </Col>

                        <Col md={4}>
                          <div className="main-form-section mt-5">
                            <Row className="justify-content-center">
                              <Form.Label column sm={3}>
                                State
                              </Form.Label>
                              <Col sm={9}>
                                <Form.Group>
                                  <InputGroup>
                                    <Form.Control
                                      type="text"
                                      name="name"
                                      disabled
                                      value={formData?.state?.name}
                                      onChange={handleChange}
                                      placeholder=""
                                      id="inputEmail3"
                                    />
                                  </InputGroup>
                                </Form.Group>
                              </Col>
                            </Row>
                          </div>
                        </Col>

                        <Col md={4}>
                          <div className="main-form-section mt-5">
                            <Row className="justify-content-center">
                              <Form.Label column sm={3}>
                                City
                              </Form.Label>
                              <Col sm={9}>
                                <Form.Group>
                                  <InputGroup>
                                    <Form.Control
                                      type="text"
                                      name="name"
                                      disabled
                                      value={formData?.city?.name}
                                      onChange={handleChange}
                                      placeholder=""
                                      id="inputEmail3"
                                    />
                                  </InputGroup>
                                </Form.Group>
                              </Col>
                            </Row>
                          </div>
                        </Col>

                        <Col md={4}>
                          <div className="main-form-section mt-5">
                            <Row className="justify-content-center">
                              <Form.Label column sm={3}>
                                Pincode
                              </Form.Label>
                              <Col sm={9}>
                                <Form.Group>
                                  <InputGroup>
                                    <Form.Control
                                      type="text"
                                      name="name"
                                      disabled
                                      value={formData?.pincode?.name}
                                      onChange={handleChange}
                                      placeholder=""
                                      id="inputEmail3"
                                    />
                                  </InputGroup>
                                </Form.Group>
                              </Col>
                            </Row>
                          </div>
                        </Col>

                        <Col md={4}>
                          <div className="main-form-section mt-5">
                            <Row className="justify-content-center">
                              <Form.Label column sm={3}>
                                Address 1
                              </Form.Label>
                              <Col sm={9}>
                                <Form.Group>
                                  <InputGroup>
                                    <Form.Control
                                      type="text"
                                      name="name"
                                      disabled
                                      value={formData?.address}
                                      onChange={handleChange}
                                      placeholder=""
                                      id="inputEmail3"
                                    />
                                  </InputGroup>
                                </Form.Group>
                              </Col>
                            </Row>
                          </div>
                        </Col>

                        <Col md={4}>
                          <div className="main-form-section mt-5">
                            <Row className="justify-content-center">
                              <Form.Label column sm={3}>
                                Address 2
                              </Form.Label>
                              <Col sm={9}>
                                <Form.Group>
                                  <InputGroup>
                                    <Form.Control
                                      type="text"
                                      name="name"
                                      disabled
                                      value={formData?.alt_address}
                                      onChange={handleChange}
                                      placeholder=""
                                      id="inputEmail3"
                                    />
                                  </InputGroup>
                                </Form.Group>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </TabPanel>
                <TabPanel>
                  <Row>
                    <Col lg={12} md={12} className=" mx-auto Add-content">
                      <Row>
                        {formData?.leads_requirements?.map(
                          (leads_requirement, outerIndex) => (
                            <React.Fragment key={outerIndex}>
                              {leads_requirement?.leads_requirement_products?.map(
                                (product, innerIndex) => (
                                  <React.Fragment key={innerIndex}>
                                    <Col md={4}>
                                      <div className="main-form-section mt-5">
                                        <Row className="justify-content-center">
                                          <Form.Label column sm={3}>
                                            Product Name
                                          </Form.Label>
                                          <Col sm={9}>
                                            <Form.Group>
                                              <InputGroup>
                                                <Form.Control
                                                  type="text"
                                                  name="name"
                                                  disabled
                                                  value={product?.product?.name}
                                                  onChange={handleChange}
                                                  id="inputEmail3"
                                                />
                                              </InputGroup>
                                            </Form.Group>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>

                                    <Col md={4}>
                                      <div className="main-form-section mt-5">
                                        <Row className="justify-content-center">
                                          <Form.Label column sm={3}>
                                            Quantity
                                          </Form.Label>
                                          <Col sm={9}>
                                            <Form.Group>
                                              <InputGroup>
                                                <Form.Control
                                                  type="text"
                                                  name="name"
                                                  disabled
                                                  value={
                                                    product?.total_quantity
                                                  }
                                                  onChange={handleChange}
                                                  id="inputEmail3"
                                                />
                                              </InputGroup>
                                            </Form.Group>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>

                                    <Col md={4}>
                                      <div className="main-form-section mt-5">
                                        <Row className="justify-content-center">
                                          <Form.Label column sm={3}>
                                            Price
                                          </Form.Label>
                                          <Col sm={9}>
                                            <Form.Group>
                                              <InputGroup>
                                                <Form.Control
                                                  type="text"
                                                  name="name"
                                                  disabled
                                                  value={product?.total_price}
                                                  onChange={handleChange}
                                                  id="inputEmail3"
                                                />
                                              </InputGroup>
                                            </Form.Group>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                  </React.Fragment>
                                )
                              )}

                              <Col md={4}>
                                <div className="main-form-section mt-5">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      Total Quantity
                                    </Form.Label>
                                    <Col sm={9}>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="text"
                                            name="name"
                                            disabled
                                            value={
                                              leads_requirement?.total_quantity
                                            }
                                            onChange={handleChange}
                                            id="inputEmail3"
                                          />
                                        </InputGroup>
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="main-form-section mt-5">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      Total Price
                                    </Form.Label>
                                    <Col sm={9}>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="text"
                                            name="name"
                                            disabled
                                            value={
                                              leads_requirement?.total_price
                                            }
                                            onChange={handleChange}
                                            id="inputEmail3"
                                          />
                                        </InputGroup>
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                            </React.Fragment>
                          )
                        )}
                      </Row>
                    </Col>
                  </Row>
                </TabPanel>

                <TabPanel>
                  <Row>
                    <Col lg={12} md={12} className=" mx-auto Add-content">
                      <Row>
                        {formData?.lead_services?.map(
                          (lead_service, outerIndex) => (
                            <React.Fragment key={outerIndex}>
                              {lead_service?.lead_service_details?.map(
                                (service, innerIndex) => (
                                  <React.Fragment key={innerIndex}>
                                    <Col md={6}>
                                      <div className="main-form-section mt-5">
                                        <Row className="justify-content-center">
                                          <Form.Label column sm={3}>
                                            Service Name
                                          </Form.Label>
                                          <Col sm={9}>
                                            <Form.Group>
                                              <InputGroup>
                                                <Form.Control
                                                  type="text"
                                                  name="name"
                                                  disabled
                                                  value={service?.service?.name}
                                                  onChange={handleChange}
                                                  placeholder=""
                                                />
                                              </InputGroup>
                                            </Form.Group>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>

                                    <Col md={6}>
                                      <div className="main-form-section mt-5">
                                        <Row className="justify-content-center">
                                          <Form.Label column sm={3}>
                                            Price
                                          </Form.Label>
                                          <Col sm={9}>
                                            <Form.Group>
                                              <InputGroup>
                                                <Form.Control
                                                  type="text"
                                                  name="price"
                                                  disabled
                                                  value={service?.price}
                                                  onChange={handleChange}
                                                />
                                              </InputGroup>
                                            </Form.Group>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                  </React.Fragment>
                                )
                              )}
                            </React.Fragment>
                          )
                        )}
                        {formData?.lead_services?.[0] && (
                          <Col md={6}>
                            <div className="main-form-section mt-5">
                              <Row className="justify-content-center">
                                <Form.Label column sm={3}>
                                  Total Price
                                </Form.Label>
                                <Col sm={9}>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        name="total_quantity"
                                        disabled
                                        value={formData.lead_services[0].total}
                                        onChange={handleChange}
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        )}
                      </Row>
                    </Col>
                  </Row>
                </TabPanel>

                <TabPanel>
                  <Row>
                    <Col lg={12} md={12} className=" mx-auto Add-content">
                      <Row>
                        <Col md={4}>
                          <div className="main-form-section mt-5">
                            <Row className="justify-content-center">
                              <Form.Label column sm={3}>
                                Lead Status
                              </Form.Label>
                              <Col sm={9}>
                                <Form.Group>
                                  <InputGroup>
                                    <Form.Control
                                      type="text"
                                      name="name"
                                      disabled
                                      value={formData?.lead_status?.name}
                                      onChange={handleChange}
                                      id="inputEmail3"
                                    />
                                  </InputGroup>
                                </Form.Group>
                              </Col>
                            </Row>
                          </div>
                        </Col>

                        <Col md={4}>
                          <div className="main-form-section mt-5">
                            <Row className="justify-content-center">
                              <Form.Label column sm={3}>
                                Lead Status Details
                              </Form.Label>
                              <Col sm={9}>
                                <Form.Group>
                                  <InputGroup>
                                    <Form.Control
                                      type="text"
                                      name="name"
                                      disabled
                                      value={formData?.lead_status_detail?.name}
                                      onChange={handleChange}
                                      placeholder=""
                                      id="inputEmail3"
                                    />
                                  </InputGroup>
                                </Form.Group>
                              </Col>
                            </Row>
                          </div>
                        </Col>

                        <Col md={4}>
                          <div className="main-form-section mt-5">
                            <Row className="justify-content-center">
                              <Form.Label column sm={3}>
                                Next Date
                              </Form.Label>
                              <Col sm={9}>
                                <Form.Group>
                                  <InputGroup>
                                    <Form.Control
                                      type="text"
                                      name="name"
                                      disabled
                                      value={formData?.next_date?.split("T")[0]}
                                      onChange={handleChange}
                                      placeholder=""
                                      id="inputEmail3"
                                    />
                                  </InputGroup>
                                </Form.Group>
                              </Col>
                            </Row>
                          </div>
                        </Col>

                        <Col md={4}>
                          <div className="main-form-section mt-5">
                            <Row className="justify-content-center">
                              <Form.Label column sm={3}>
                                Next Time
                              </Form.Label>
                              <Col sm={9}>
                                <Form.Group>
                                  <InputGroup>
                                    <Form.Control
                                      type="text"
                                      name="name"
                                      disabled
                                      value={formData?.next_time}
                                      onChange={handleChange}
                                      placeholder=""
                                      id="inputEmail3"
                                    />
                                  </InputGroup>
                                </Form.Group>
                              </Col>
                            </Row>
                          </div>
                        </Col>

                        <Col md={8}>
                          <div className="main-form-section mt-5">
                            <Row className="justify-content-center">
                              <Form.Label column sm={3}>
                                Remark
                              </Form.Label>
                              <Col sm={9}>
                                <Form.Group>
                                  <InputGroup>
                                    <Form.Control
                                      type="text"
                                      name="name"
                                      disabled
                                      value={formData?.remark}
                                      onChange={handleChange}
                                      placeholder=""
                                      id="inputEmail3"
                                    />
                                  </InputGroup>
                                </Form.Group>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </TabPanel>
              </Tabs>
              {/* </Col>
                </Row> */}
              {/* </Container> */}
            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas>

      {/* <!-- Modal --> */}
      <div className="save-modal">
        <div
          className={`modal fade ${showModal ? "show" : ""}`}
          style={{ display: showModal ? "block" : "none" }}
          id="exampleModal1"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="circle justify-content-end">
                  <img src={circle} className="circle-img mb-2" alt="" />
                </div>
                <h1 className="add-success text-center">
                  Lead Status Updated Successfully
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Modal --> */}
      <div className="save-modal">
        <div
          className={`modal fade ${showErrorModal ? "show" : ""}`}
          style={{ display: showErrorModal ? "block" : "none" }}
          id="exampleModal1"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="circle justify-content-end">
                  <img src={circle} className="circle-img mb-2" alt="" />
                </div>
                <h1 className="add-success text-center">
                  Lead Status Already Exits
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// ****************************************************************Table***************************************************************

const Tables = () => {
  const {
    getData,
    editStatusData,
    deleteData,
    ErrorNotify,
    isAllow,
    Per_Page_Dropdown,
  } = useContext(Context);

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState(5);
  const [search, setSearch] = useState("");
  const [reset, setReset] = useState();

  const [show, setShowAdd] = useState(false);
  const [show1, setShowEdit] = useState(0);
  const [changeStatus, setChangeStatus] = useState();
  const [option, setOption] = useState();

  // Modal function
  const handleClose = () => setShowAdd(false);
  const handleShow = () => setShowAdd(true);

  const [formData, setFormData] = useState({
    user_id: "",
    lead_status_id: "",
    ids: [],
  });

  const [user_id, setuser_id] = useState({});
  const [lead_status_id, setlead_status_id] = useState({});

  const getDataAll = async () => {
    const response = await getData(
      `/lead_management/leads?page=${currentPage}&per_page=${perPage}&term=${search}&user_id=${user_id?.value}&lead_status_id=${lead_status_id?.value}`
    );
    await setData(response);
    setCurrentPage(response?.data?.currentPage);
    setperPage(response?.data?.per_page);
    setSearch(response?.data?.search_name);
    // setCurrentPage(response?.data?.);
    setOption(await Per_Page_Dropdown(response?.data?.totalEntries));
    const newData = response?.data?.data;
    if (newData) {
      const newIds = newData.map((d) => d?.id);
      setAllChecked(newIds);
    }
  };

  useEffect(() => {
    getDataAll();
  }, [changeStatus, perPage, reset, show, show1, formData]);

  const ChangeStatus = async (id) => {
    const response = await editStatusData(`/lead_management/leads/${id}`);
    setChangeStatus(response);
  };

  const handleClose1 = () => setShowEdit(0);
  const handleShow1 = (id) => {
    setShowEdit(id);
  };

  // Select All Functionality
  const [selectAllChecked, setSelectAllChecked] = useState([]);
  const [allChecked, setAllChecked] = useState([]);

  const handleSelectAll = async () => {
    await setSelectAllChecked(allChecked);
  };

  const handleChange = async (e) => {
    const { value, checked } = e.target;

    if (value === "selectAll") {
      if (checked) {
        handleSelectAll();
      } else {
        await setSelectAllChecked([]);
      }
    } else {
      if (checked) {
        await setSelectAllChecked([...selectAllChecked, Number(value)]);
      } else {
        await setSelectAllChecked(
          selectAllChecked.filter((e) => e !== Number(value))
        );
      }
    }
  };

  const handleSelect = async (e) => {
    setFormData({ ...formData, [e.name]: e });
  };

  const [users, setUsers] = useState([]);
  const [leadstatus, setleadstatus] = useState([]);

  const getAllDataSelect = async () => {
    const usersdata = await getData(`/masters/alltele-callers`);
    setUsers(usersdata?.data);
    const response = await getData("/masters/allleadstatus");

    const option = [];
    if (response.success) {
      response?.data?.map((data) => {
        option.push({
          value: data.id,
          name: "lead_status_id",
          label: data.name,
        });
      });
    }
    setleadstatus(option);
  };

  useEffect(() => {
    getAllDataSelect();
  }, []);

  useEffect(() => {
    setFormData({ ...formData, ids: selectAllChecked });
    if (selectAllChecked.length === 0) {
      LeadTransferHide();
      setFormData({ user_id: "", lead_status_id: "", ids: [] });
    }
  }, [selectAllChecked]);

  //  column hide and show.....
  const [visible, setVisibel] = useState({
    col0: true,
    col1: true,
    col2: true,
    col3: true,
    col4: true,
    col5: true,
    col6: true,
    col7: true,
    col8: true,
    col9: true,
    col10: true,
    col11: true,
    col12: true,
    col13: true,
  });

  //toggle columnns.........
  const toggleColumn = (event, columnName) => {
    event.preventDefault();
    setVisibel((prev) => ({
      ...prev,
      [columnName]: !prev[columnName],
    }));
  };

  // Lead Transfer
  const [leadtransfer, setLeadtransfer] = useState(false);

  const LeadTransferShow = () => {
    if (selectAllChecked.length === 0) {
      alert("Please Select At Least One Record");
    } else {
      setLeadtransfer(true);
    }
  };

  const LeadTransferHide = () => {
    setLeadtransfer(false);
  };
  const [errors, setErrors] = useState({});

  const LeadTransfer = async () => {
    try {
      const response = await postData("/lead_management/leads", formData);

      if (response?.success) {
        setFormData({ user_id: "", lead_status_id: "", ids: [] });
        setSelectAllChecked([]);
        setErrors({});
      } else {
        setErrors(response?.message?.errors);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <>
      <div className="main-advancedashboard">
        <Header title={"Leads"} link={"/lead_management/leads"} />
        <section className="AdvanceDashboard">
          <div className="col-xxl-12 col-xl-12  ">
            <section className="Tabels tab-radio tab-radio mt-5">
              <div className="">
                <div className="row">
                  <div className="d-flex">
                    <div className="add me-3">
                      <Link type="button" className="btn btn-add pe-3">
                        <div onClick={() => handleShow()}>
                          <img src={plus} className="plus me-2 ms-0" alt="" />
                          Lead Bulk Upload
                        </div>
                      </Link>
                    </div>

                    <div className="add">
                      <div className="dropdown">
                        <button
                          className="btn btn-columns dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img src={colunms} className="columns " alt="" />
                          Column Selection{" "}
                          <img src={top} className="top ms-1" alt="" />{" "}
                        </button>
                        <ul className="dropdown-menu">
                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col0")}
                              href="#"
                            >
                              Check Box
                              {visible?.col0 ? (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col1")}
                              href="#"
                            >
                              Sr. No.
                              {visible?.col1 ? (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>

                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col7")}
                              href="#"
                            >
                              Employee Name
                              {visible?.col7 ? (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col2")}
                              href="#"
                            >
                              First Name
                              {visible?.col2 ? (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>

                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col6")}
                              href="#"
                            >
                              Last Name
                              {visible?.col6 ? (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>

                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col5")}
                              href="#"
                            >
                              Mobile No
                              {visible?.col5 ? (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>

                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col7")}
                              href="#"
                            >
                              Lead Status
                              {visible?.col7 ? (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>

                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col3")}
                              href="#"
                            >
                              Status
                              {visible?.col3 ? (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col4")}
                              href="#"
                            >
                              Action
                              {visible?.col4 ? (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="border-line mt-3"></div>
                  <div className="row mt-4">
                    <div className="  col-lg-4 col-md-4 col-12">
                      <div className="d-flex">
                        <div className="show me-2">
                          <p className="show mt-1">Show</p>
                        </div>
                        <div className="number me-2">
                          <select
                            className="form-select form-select-sm"
                            aria-label=".form-select-sm example"
                            onChange={(e) => setperPage(e.target.value)}
                          >
                            {option?.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="entries">
                          <p className="show mt-1">entries</p>
                        </div>
                      </div>
                    </div>
                    <div className="  col-lg-8  col-md-8 col-12">
                      <div className="d-flex justify-content-end">
                        <div className="sowing me-2">
                          <p className="show mt-2">{`Showing ${Math.min(
                            (currentPage - 1) * perPage + 1
                          )} to ${Math.min(
                            currentPage * perPage,
                            data?.data?.totalEntries
                          )} of ${data?.data?.totalEntries} entries`}</p>
                        </div>
                        <div className="num me-2">
                          <input
                            type="text"
                            className="form-control"
                            id=""
                            value={search}
                            onChange={(e) => {
                              setSearch(e.target.value);
                            }}
                          />
                        </div>
                        <div className="Search me-2">
                          <button
                            type="button"
                            onClick={getDataAll}
                            className="btn btn-search"
                          >
                            <img src={search1} className="search" alt="" />
                          </button>
                        </div>

                        <div className="Search-1">
                          <button
                            type="button"
                            onClick={() => {
                              setSearch("");
                              setlead_status_id("");
                              setuser_id("");
                              setReset(!reset);
                            }}
                            className="btn btn-reset"
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="row">
                  <div className="col-md-3  mt-3"></div>
                  <div className="col-md-3  mt-3">
                    <Select
                      name="user_id"
                      className="custom-select"
                      onChange={(e) => {
                        setuser_id(e);
                      }}
                      placeholder="Tele Caller"
                      value={user_id}
                      options={users}
                    />
                    {errors ? (
                      <p
                        style={{
                          color: "red",
                          fontSize: "11px",
                          textAlign: "center",
                        }}
                      >
                        {errors?.user_id?.msg}
                      </p>
                    ) : null}
                  </div>
                  <div className="col-md-3  mt-3">
                    <Select
                      name="lead_status_id"
                      className="custom-select"
                      placeholder="Lead Status"
                      onChange={(e) => {
                        setlead_status_id(e);
                      }}
                      value={lead_status_id}
                      options={leadstatus}
                    />
                    {errors ? (
                      <p
                        style={{
                          color: "red",
                          fontSize: "11px",
                          textAlign: "center",
                        }}
                      >
                        {errors?.lead_status_id?.msg}
                      </p>
                    ) : null}
                  </div>
                </div> */}

                <div className="">
                  <div className="row mt-4">
                    <div className="col-lg-3 col-md-3 col-12">
                      Lead Report :
                      <Button
                        variant="info"
                        onClick={LeadTransferShow}
                        className="ms-1"
                      >
                        Lead Transfer
                      </Button>
                    </div>
                    {leadtransfer ? (
                      <>
                        <div className="col-lg-3 col-md-3 col-12">
                          <Select
                            name="user_id"
                            className="custom-select"
                            onChange={handleSelect}
                            value={formData?.user_id}
                            options={users}
                          />
                          {errors ? (
                            <p
                              style={{
                                color: "red",
                                fontSize: "11px",
                                textAlign: "center",
                              }}
                            >
                              {errors?.user_id?.msg}
                            </p>
                          ) : null}
                        </div>

                        <div className="col-lg-3 col-md-3 col-12">
                          <Select
                            name="lead_status_id"
                            className="custom-select"
                            onChange={handleSelect}
                            value={formData?.lead_status_id}
                            options={leadstatus}
                          />
                          {errors ? (
                            <p
                              style={{
                                color: "red",
                                fontSize: "11px",
                                textAlign: "center",
                              }}
                            >
                              {errors?.lead_status_id?.msg}
                            </p>
                          ) : null}
                        </div>
                        <div className="col-lg-3 col-md-3 col-12">
                          <Button variant="primary" onClick={LeadTransfer}>
                            Transfer
                          </Button>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="border-line mt-3"></div>
                <div className="row mt-3">
                  <div className="data table-responsive">
                    <Table striped bordered hover responsive center>
                      <thead>
                        <tr className="">
                          {visible.col0 && (
                            <th className="check round-check">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value="selectAll"
                                  checked={
                                    allChecked.length ===
                                    selectAllChecked.length
                                  }
                                  onChange={handleChange}
                                  id="selectAll"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckChecked"
                                ></label>
                              </div>
                            </th>
                          )}
                          {visible.col1 && <th className="sr">Sr. No.</th>}
                          {visible.col7 && (
                            <th className="tax-name">Employee Name</th>
                          )}
                          {visible.col2 && (
                            <th className="tax-name">First Name</th>
                          )}

                          {visible.col6 && (
                            <th className="active">Last Name</th>
                          )}

                          {visible.col5 && (
                            <th className="active">Mobile No</th>
                          )}
                          {visible.col7 && (
                            <th className="active">Lead Status</th>
                          )}
                          {visible.col3 && <th className="tax-name">Status</th>}
                          {visible.col4 && <th className="active">Action</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {isAllow.includes(89) ? (
                          data?.data?.data?.map((d, index) => {
                            return (
                              <tr className="" key={index}>
                                {visible.col0 && (
                                  <td>
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value={d?.id}
                                        name="perselected"
                                        checked={selectAllChecked.includes(
                                          d?.id
                                        )}
                                        onChange={handleChange}
                                        id="flexCheckDefault"
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="flexCheckDefault"
                                      ></label>
                                    </div>
                                  </td>
                                )}
                                {visible.col1 && <td>{++index}.</td>}
                                {visible.col7 && <td>{d?.user?.name}</td>}
                                {visible.col2 && <td>{d?.f_name}</td>}
                                {visible.col6 && <td>{d?.l_name}</td>}
                                {visible.col5 && <td>{d?.mobile_no}</td>}
                                {visible.col7 && (
                                  <td>{d?.lead_status?.name}</td>
                                )}
                                {visible.col3 && (
                                  <td>
                                    <div className="form-check form-switch">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        checked={d.status}
                                        onChange={() => {
                                          ChangeStatus(d.id);
                                        }}
                                        id={`flexSwitchCheckDefault${d?.id}`}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={`flexSwitchCheckDefault${d?.id}`}
                                      >
                                        {d.status ? "Active" : "Inactive"}
                                      </label>
                                    </div>
                                  </td>
                                )}

                                {visible.col4 && (
                                  <td>
                                    <div className="d-flex">
                                      {isAllow.includes(91) ? (
                                        <Button
                                          onClick={() => handleShow1(d?.id)}
                                          type="button"
                                          className="btn btn-primary me-1"
                                        >
                                          <FontAwesomeIcon icon="fa-solid fa-eye" />
                                        </Button>
                                      ) : (
                                        <></>
                                      )}
                                      {isAllow.includes(91) ? (
                                        <>
                                          {/*                                         
                                          <Button
                                            // to={`/lead_management/leads/edit/${d?.id}`}
                                            onClick={() => handleShow1(d?.id)}
                                            type="button"
                                            className="btn btn-primary me-1"
                                          >
                                            <img
                                              src={pen}
                                              className="pen"
                                              alt=""
                                            />
                                          </Button>{" "} */}
                                        </>
                                      ) : (
                                        <></>
                                      )}

                                      {isAllow.includes(92) ? (
                                        <>
                                          {/* <button
                                          onClick={() => {
                                            showDeleteRecord(d?.id, d?.name);
                                          }}
                                          type="button"
                                          className="btn btn-danger"
                                        >
                                          <img
                                            src={basket}
                                            className="pen"
                                            alt=""
                                          />
                                        </button> */}
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </td>
                                )}
                              </tr>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>

      {show ? (
        <AddOffCanvance
          handleClose={handleClose}
          setShow={setShowAdd}
          show={show}
        />
      ) : (
        ""
      )}

      {show1 ? (
        <EditOffCanvance
          handleClose={handleClose1}
          setShow={setShowEdit}
          show={show1}
        />
      ) : (
        ""
      )}

      {/* <!-- Modal Delete --> */}
      {/* <div className="upload-modal">
        <div
          className={`modal fade ${showDeleteModal ? "show" : ""}`}
          style={{ display: showDeleteModal ? "block" : "none" }}
          id="exampleModaldel"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <h1 className="add-success text-center  mt-2">
                  Are you sure ?
                </h1>
                <p>
                  Do You Really Want to Delete These Record?
                  <br /> This Process Cannot Be Undone{" "}
                </p>
                <div className="button-holder text-center mt-2">
                  <button
                    className="btn btn-yes me-2"
                    onClick={handleDeleteRecord}
                  >
                    Yes
                  </button>
                  <button className="btn btn-no" onClick={handleDeleteCancel}>
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <Toaster position="top-right" />
    </>
  );
};

export default Tables;
