import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Row,
  Col,
  Form,
  Modal,
  Button,
  FloatingLabel,
  Tab,
  Tabs,
} from "react-bootstrap";
import Select from "react-select";
import CustomDatePicker from "../Admin/CustomDatePicker";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import { Context } from "../../utils/context";
import Cookies from "js-cookie";
import Spinner from "react-bootstrap/Spinner";

const ActionandDeposition = (props) => {
  const { postData, getData } = useContext(Context);
  const [submit, setSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleSubmitClose = () => setSubmit(false);
  const handleSubmitShow = () => {
    setSubmit(true);
    setTimeout(() => {
      setSubmit(false);
    }, 5000);
  };
  const [leadStatus, setLeadStatus] = useState([]);
  const [leadStatusDetails, setleadStatusDetails] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    lead_id: props?.lead_id,
    lead_status_id: "",
    lead_status_details_id: "",
    next_date: "",
    next_time: "",
    remark: "",
  });

  useEffect(() => {
    if (props?.lead_id !== "") {
      setFormData({ ...formData, lead_id: props?.lead_id });
    }
  }, [props?.lead_id]);

  const [validated, setValidated] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await postData(
        "/lead_management/lead-action-and-deposition",
        formData
      );
      if (response?.success) {
        setFormData({
          ...formData,
          lead_id: response?.data?.lead_id,
        });
        Cookies.remove("lead_id");
        props.setFormStatus({ ...props.formStatus, lead_id: "" });
        handleSubmitShow();
        setErrors({});
      } else {
        if (response?.message?.errors?.lead_id) {
          props.handleErrorShow();
        } else {
          setErrors(response?.message?.errors);
        }
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setLoading(false);
    }
  };

  const getSelectData = async (name = null, id = null) => {
    if (name === "lead_status_id") {
      const response = await getData(`/masters/allleadstatusdetails/${id}`);

      const option = [];
      if (response?.success) {
        response?.data?.map((data) => {
          option.push({
            value: data.id,
            name: "lead_status_details_id",
            label: data.name,
          });
        });
        setleadStatusDetails(option);
      }
    } else {
      const res = await getData("/masters/allleadstatus");

      const option = [];
      if (res?.success) {
        res?.data?.map((data) => {
          option.push({
            value: data.id,
            name: "lead_status_id",
            label: data.name,
          });
        });
      }
      setLeadStatus(option);
    }
  };

  useEffect(() => {
    getSelectData();
  }, []);

  const handleChange = (e) => {
    if (e?.name === "lead_status_id" || e?.name === "lead_status_details_id") {
      if (e?.name === "lead_status_id") {
        setFormData({
          ...formData,
          [e.name]: e,
          lead_status_details_id: "",
        });
        getSelectData(e.name, e.value);
      } else if (e?.name === "lead_status_details_id") {
        setFormData({
          ...formData,
          [e.name]: e,
        });
      }
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const getEditData = async () => {
    const lead_id = Cookies.get("lead_id");
    if (lead_id) {
      const response = await getData(
        `/lead_management/lead-action-and-deposition/${lead_id}`
      );
      setFormData(response?.data);
    }
  };

  useEffect(() => {
    getSelectData();
    const lead_id = Cookies.get("lead_id");
    if (lead_id) {
      getEditData();
    } else {
      setFormData({
        lead_id: props?.lead_id,
        lead_status_id: "",
        lead_status_details_id: "",
        next_date: "",
        next_time: "",
        remark: "",
      });
    }
  }, [props?.formStatus]);

  const next_Date = formData?.next_date ? new Date(formData.next_date) : null;

  return (
    <div className="main-box">
      <div className="box-title-div">
        <div className="row">
          <div className="col-md-10 pe-0">
            <div className="sec-1">
              <p className="call-statusp">Action and Deposition </p>
            </div>
          </div>
          <div className="col-md-2 ps-0">
            <div className="sec-2">
              <div className="d-flex justify-content-center">
                {props.formStatus.four ? (
                  <Button
                    className="save-fafa p-0 btn"
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    {loading ? (
                      <Spinner
                        animation="border"
                        role="status"
                        size="sm"
                        className="me-2"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    ) : (
                      <FontAwesomeIcon
                        className="save-fafa"
                        icon="fa-solid fa-floppy-disk"
                      />
                    )}
                  </Button>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="box-content-div pc-sec pb-0">
        <div className="washing-mach-sec">
          <div className="row mt-2">
            <div className="col-md-6">
              <p className="servicing-ppp">Date: 3/11/23</p>
            </div>
            <div className="col-md-6 text-end">
              <p className="servicing-ppp">Time : 16.53.32</p>
            </div>
          </div>

          <div className="electt-divvv mt-2">
            <Select
              placeholder="Covert"
              className="custom-select "
              name="lead_status_id"
              options={leadStatus}
              onChange={handleChange}
              value={formData?.lead_status_id}
            />
            {errors ? (
              <p style={{ color: "red", fontSize: "11px" }}>
                {errors?.lead_status_id?.msg}
              </p>
            ) : null}

            <Select
              placeholder="Select Status Details"
              className="custom-select "
              name="lead_status_details_id"
              options={leadStatusDetails}
              onChange={handleChange}
              value={formData?.lead_status_details_id}
            />
            {errors ? (
              <p style={{ color: "red", fontSize: "11px" }}>
                {errors?.lead_status_details_id?.msg}
              </p>
            ) : null}

            <Form>
              <div className="row">
                <div className="col-xl-6 col-lg-4">
                  <Form.Group className="form-group" controlId="">
                    <Form.Label className="label-name">Date:</Form.Label>

                    <CustomDatePicker
                      wrapperClassName="datepicker"
                      className="form-control ms-4"
                      selected={next_Date}
                      onChange={(e) => {
                        const selectedDate = new Date(e);
                        const currentDate = new Date();
                        currentDate.setHours(0, 0, 0, 0);
                        if (selectedDate >= currentDate) {
                          setFormData({
                            ...formData,
                            next_date: e,
                          });
                        } else {
                          const newerrors = { ...errors };
                          newerrors.next_date = {
                            msg: "Please select a current or future date.",
                          };
                          setErrors(newerrors);
                        }
                      }}
                      minDate={new Date()}
                    />
                  </Form.Group>
                  {errors ? (
                    <p style={{ color: "red", fontSize: "11px" }}>
                      {errors?.next_date?.msg}
                    </p>
                  ) : null}
                </div>
                <div className="col-xl-6 col-lg-5">
                  <Form.Group className="form-group" controlId="">
                    <TimePicker
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          next_time: e,
                        });
                      }}
                      value={formData?.next_time}
                    />
                  </Form.Group>
                  {errors ? (
                    <p style={{ color: "red", fontSize: "11px" }}>
                      {errors?.next_time?.msg}
                    </p>
                  ) : null}
                </div>

                <div className="col-md-12">
                  <Form.Group className="form-group" controlId="">
                    <Form.Control
                      type="text"
                      name="remark"
                      value={formData?.remark}
                      onChange={handleChange}
                      placeholder="Remark:"
                    />
                  </Form.Group>
                  {errors ? (
                    <p style={{ color: "red", fontSize: "11px" }}>
                      {errors?.remark?.msg}
                    </p>
                  ) : null}
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>

      <section>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="lead-send-pop-section"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Message
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="client-details">
              <Row>
                <Col>
                  <p className="cl-name">Name : Amar Thakur</p>
                </Col>
                <Col>
                  <div className="alignnn-mob">
                    <p className="cl-name">9860988922</p>
                    <p className="cl-name">amar@gmail.com</p>
                  </div>
                </Col>
              </Row>
            </div>

            <div className="sendTabsSec">
              <Tabs
                defaultActiveKey="sendwhatsapp"
                id="justify-tab-example"
                className="mb-3"
              >
                <Tab eventKey="sendwhatsapp" title="SEND WHATSAPP">
                  <Row>
                    <Col md={10} className="mx-auto">
                      <Form
                        noValidate
                        validated={validated}
                        onSubmit={handleSubmit}
                      >
                        <Form.Group controlId="validationCustom01">
                          <Form.Label>Select Template</Form.Label>

                          <Select
                            placeholder="Select Template"
                            name=""
                            className="custom-select"
                            value=""
                          />
                        </Form.Group>

                        <div className="text-center mt-3">
                          <Button className="form-send-btnnn">Send</Button>
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </Tab>

                <Tab eventKey="sendsms" title="SEND SMS">
                  <Row>
                    <Col md={10} className="mx-auto">
                      <Form
                        noValidate
                        validated={validated}
                        onSubmit={handleSubmit}
                      >
                        <Form.Group
                          className="spc-ll"
                          controlId="validationCustom01"
                        >
                          <Form.Label>Select Template</Form.Label>

                          <Select
                            placeholder="Select Template"
                            name=""
                            className="custom-select"
                            value=""
                          />
                        </Form.Group>

                        <Form.Group className="spc-ll">
                          <FloatingLabel
                            controlId="floatingTextarea2"
                            label="Comments"
                          >
                            <Form.Control
                              as="textarea"
                              placeholder="Leave a comment here"
                              style={{ height: "100px" }}
                            />
                          </FloatingLabel>
                        </Form.Group>

                        <div className="text-center mt-3">
                          <Button className="form-send-btnnn">Send</Button>
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </Tab>

                <Tab eventKey="sendmail" title="SEND MAIL">
                  <Row>
                    <Col md={10} className="mx-auto">
                      <Form
                        noValidate
                        validated={validated}
                        onSubmit={handleSubmit}
                      >
                        <Form.Group
                          className="spc-ll"
                          controlId="validationCustom01"
                        >
                          <Form.Label>Mail Heading</Form.Label>

                          <Select
                            placeholder="Mail Heading"
                            name=""
                            className="custom-select"
                            value=""
                          />
                        </Form.Group>

                        <Form.Group
                          className="spc-ll"
                          controlId="validationCustom01"
                        >
                          <Form.Label>Select Template</Form.Label>

                          <Select
                            placeholder="Select Template"
                            name=""
                            className="custom-select"
                            value=""
                          />
                        </Form.Group>

                        <Form.Group className="spc-ll">
                          <FloatingLabel
                            controlId="floatingTextarea2"
                            label="Comments"
                          >
                            <Form.Control
                              as="textarea"
                              placeholder="Leave a comment here"
                              style={{ height: "100px" }}
                            />
                          </FloatingLabel>
                        </Form.Group>

                        <div className="text-center mt-3">
                          <Button className="form-send-btnnn">Send</Button>
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </Tab>
              </Tabs>
            </div>
          </Modal.Body>
        </Modal>
      </section>

      <section>
        <Modal
          show={submit}
          onHide={handleSubmitClose}
          backdrop="static"
          keyboard={false}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="addproduct-pop-section"
        >
          <Modal.Header closeButton>
            <Modal.Title>Action and Deposition Submitted</Modal.Title>
          </Modal.Header>

          <div className="box">
            <div className="success alert">
              <div className="alert-body">Success !</div>
            </div>
          </div>
        </Modal>
      </section>
    </div>
  );
};

export default ActionandDeposition;
