import React, { useContext, useEffect, useState } from "react";
import "./CompliantListTable.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Form, Pagination, Table } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import search1 from "../../../../Components/Admin/assets/icons/search.png";
import Button from "react-bootstrap/Button";
import StatusChangemodal from "../all-table-modal/StatusChangemodal";
import CompalintTransferModal from "../all-table-modal/CompalintTransferModal";
import AddNewComplaintModal from "../all-table-modal/AddNewComplaintModal";
import ScheduleDateChangemodal from "../all-table-modal/ScheduleDateModel";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Context } from "../../../../utils/context";
import CustomPagination from "../../../common/CustomPagination";
import { useLocation } from "react-router-dom";
import DeleteModal from "../all-table-modal/DeleteModal";

const ComplaintListTable = (props) => {
  const [showFeedback, setShowFeedback] = useState(false);

  const handleCloseFeedback = () => setShowFeedback(false);
  const handleShowFeedback = () => setShowFeedback(true);
  const location = useLocation();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const {
    getData,
    getDownloadDataExcel,
    Select2Data,
    deleteData,
    setComplaintStatus,
    complaintstatus,
    IMG_URL,
    Per_Page_Dropdown,
  } = useContext(Context);
  const [modalShow, setModalShow] = useState(false);
  const [modalComplaint, setModalComplaint] = useState(false);
  const [modalScheduleDate, setModalScheduleDate] = useState(false);
  const [modalAdd, setModalAdd] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [perPage, setperPage] = useState(10);
  const [option, setOption] = useState();

  const handleFromChange = (e) => {
    const fromDate = e.target.value;
    setFrom(fromDate);
  };

  const handleToChange = (e) => {
    const toDate = e.target.value;
    setTo(toDate);
  };

  const [reset, setReset] = useState();
  const [status, setStatus] = useState("");
  const [searchStatus, setSearchStatus] = useState(complaintstatus);
  const [priority, setPriority] = useState("");
  const [searchPriority, setSearchPriority] = useState("");
  const [data, setData] = useState({ totalPages: 1, currentPage: 1 });
  const [dashboard, setDashborad] = useState({});

  const getAllData = async (page = 1) => {
    {
      const res = await getData(
        `/complaint-management/complaints?page=${currentPage}&per_page=${perPage}&term=${search} &from=${from} &to=${to} 
         &complaint_status=${
           searchStatus?.value ? searchStatus?.value : ""
         }&complaint_priorities=${
          searchPriority?.value ? searchPriority?.value : ""
        }`
      );
      setData(res?.data);
      setCurrentPage(res?.data?.currentPage);
      setperPage(res?.data?.per_page);
      setSearch(res?.data?.search_name);
      setFrom(res?.data?.from);
      setTo(res?.data?.to);
      setStatus(res?.data?.search_name);
      setPriority(res?.data?.search_name);
      setOption(await Per_Page_Dropdown(res?.data?.totalEntries));

      const newData = res?.data?.data;
      if (newData) {
        const newIds = newData.map((d) => d?.id);
        setAllChecked(newIds);
      }
    }

    const res2 = await getData("/masters/all-complaint-status");

    if (res2?.success) {
      setStatus(await Select2Data(res2?.data, "complaint_status_id"));
    }

    const res3 = await getData("/masters/all-complaint-priorities");

    if (res3?.success) {
      setPriority(await Select2Data(res3?.data));
    }

    {
      const res = await getData(`/complaint-management/dashboard`);
      setDashborad(res?.data);
    }
  };

  useEffect(() => {
    getAllData();
  }, [modalAdd, currentPage, modalComplaint, modalShow, reset]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const statusParam = params.get("status");
    if (statusParam) {
      setSearchStatus(statusParam);
    }
  }, [location.search]);
  // Modal
  const [lgShow, setLgShow] = useState(false);
  const [statusandtransfer, setstatusandtransfer] = useState({});
  const [view, setview] = useState(false);

  const [selectAllChecked, setSelectAllChecked] = useState([]);
  const [allChecked, setAllChecked] = useState([]);

  const handleSelectAll = async () => {
    await setSelectAllChecked(allChecked);
  };

  const handleChange = async (e) => {
    const { value, checked } = e.target;

    if (value === "selectAll") {
      if (checked) {
        handleSelectAll();
      } else {
        await setSelectAllChecked([]);
      }
    } else {
      if (checked) {
        await setSelectAllChecked([...selectAllChecked, Number(value)]);
      } else {
        await setSelectAllChecked(
          selectAllChecked.filter((e) => e !== Number(value))
        );
      }
    }
  };

  const HandleDownload = async () => {
    if (selectAllChecked.length == 0) {
      alert("Please Select Atleast One Record");
    } else {
      try {
        await getDownloadDataExcel(
          "/complaint-management/complaints/download",
          selectAllChecked,
          "Complaint List"
        );
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const [modelDelete, setModelDelete] = useState(false);
  const deleteRecord = async (id = 0) => {
    console.log(show);
    console.log(id);
    const res = await deleteData(`/complaint-management/complaints/${id}`);
    if (res?.success) {
      await setModelDelete(res?.success);
    }

    setTimeout(() => {
      setModelDelete(0);
      // props.handleClose2();
      handleClose();
      getAllData();
    }, 1000);
  };

  return (
    <>
      <section className="complaint-list-table">
        <div className="card-section">
          <Row>
            <Col xl={3} lg={6} md={6}>
              <div className=" d-flex registered-number">
                <div className="circle-holder">
                  <img
                    className="ticket-img"
                    src={process.env.PUBLIC_URL + "/assets/Image/tickets.png"}
                  />
                  {/* <FontAwesomeIcon icon="fa-solid fa-envelope-open-text " className='iconses' /> */}
                </div>
                <div className="text-holder">
                  <p>Complaint Registred</p>
                  <h5> {dashboard?.complaint_registred}</h5>
                </div>
              </div>
            </Col>
            <Col xl={3} lg={6} md={6}>
              <div className=" d-flex registered-number">
                <div className="circle-holder1">
                  <img
                    className="ticket-img"
                    src={process.env.PUBLIC_URL + "/assets/Image/tickets.png"}
                  />
                  {/* <FontAwesomeIcon icon="fa-solid fa-envelope-open-text " className='iconses' /> */}
                </div>
                <div className="text-holder">
                  <p>Pending Complaint</p>
                  <h5> {dashboard?.pending_registred}</h5>
                </div>
              </div>
            </Col>
            <Col xl={3} lg={6} md={6}>
              <div className=" d-flex  mt-xl-0 mt-lg-3 mt-md-3 registered-number">
                <div className="circle-holder2">
                  <img
                    className="ticket-img"
                    src={process.env.PUBLIC_URL + "/assets/Image/tickets.png"}
                  />
                  {/* <FontAwesomeIcon icon="fa-solid fa-envelope-open-text " className='iconses' /> */}
                </div>
                <div className="text-holder">
                  <p>Closed Complaints</p>
                  <h5> {dashboard?.closed_registred}</h5>
                </div>
              </div>
            </Col>
            <Col xl={3} lg={6} md={6}>
              <div className=" d-flex mt-xl-0 mt-lg-3 mt-md-3">
                <div className="circle-holder3">
                  <img
                    className="ticket-img"
                    src={process.env.PUBLIC_URL + "/assets/Image/tickets.png"}
                  />
                  {/* <FontAwesomeIcon icon="fa-solid fa-envelope-open-text " className='iconses' /> */}
                </div>
                <div className="text-holder">
                  <p>Rejected Complaints</p>
                  <h5> {dashboard?.rejected_registred}</h5>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <Row>
          <div className="heading-holder mt-5">
            <h4>Complaint List</h4>
          </div>

          <div className="main-class-report-table">
            <Row>
              <Col xl={3} lg={3} md={5} sm={12}>
                <Form.Label>Search</Form.Label>
                <input
                  type="text"
                  className="form-control"
                  id=""
                  placeholder="Customer Name"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target?.value);
                  }}
                />
              </Col>
              <Col xl={2} lg={3} md={5} sm={12}>
                <Form.Label>Complaint Status</Form.Label>
                <Select
                  placeholder="Status"
                  className="custom-select"
                  onChange={(e) => {
                    setSearchStatus(e);
                  }}
                  value={searchStatus}
                  options={status}
                />
              </Col>

              <Col xl={2} lg={3} md={5} sm={12}>
                <Form.Label> Schedule From</Form.Label>
                <input
                  type="date"
                  className="form-control"
                  value={from}
                  onChange={handleFromChange}
                />
              </Col>
              <Col xl={2} lg={3} md={5} sm={12}>
                <Form.Label> Schedule To</Form.Label>
                <input
                  type="date"
                  className="form-control"
                  value={to}
                  onChange={handleToChange}
                  min={from} // Set the minimum allowed date for "To" as the "From" date
                />
              </Col>

              <Col xl={2} lg={3} md={5} sm={12}>
                <Form.Label>Sory By</Form.Label>
                <Select
                  placeholder="Priority"
                  className="custom-select"
                  onChange={(e) => {
                    setSearchPriority(e);
                  }}
                  value={searchPriority}
                  options={priority}
                />
              </Col>

              <Col xl={5} lg={5} md={6} sm={12}>
                <Row className="mt-1">
                  <Col xl={2} lg={2} md={2} sm={3}>
                    <Form.Label></Form.Label>
                    <div className="Search me-2">
                      <button
                        type="button"
                        onClick={getAllData}
                        className="reset-btn"
                      >
                        <img src={search1} className="search" alt="" />
                      </button>
                    </div>
                  </Col>
                  <Col xl={3} lg={3} md={2} sm={6}>
                    <Form.Label></Form.Label>
                    <div className="Search-1">
                      <button
                        type="button"
                        onClick={() => {
                          setSearch("");
                          setFrom("");
                          setTo("");
                          setSearchStatus("");
                          setSearchPriority("");

                          setReset(!reset);
                        }}
                        className="reset-btn"
                      >
                        Reset
                      </button>
                    </div>
                  </Col>

                  <Col xl={7} lg={6} md={8} sm={6}>
                    <Form.Label>Download data in Excel</Form.Label>
                    <br />
                    <FontAwesomeIcon
                      icon="fa-solid fa-file-lines"
                      className="pdf-icon"
                      variant="success"
                      // className="btn btn-view"
                      onClick={HandleDownload}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <div className="brownborder-report mt-3"></div>
            <Row>
              <div className="col-md-8">
                <div className="d-flex my-3">
                  <div className="status-button me-2">
                    <Button
                      className="btn tansfer-btn"
                      onClick={() => {
                        if (selectAllChecked.length) {
                          setModalShow(true);
                        } else {
                          alert("Please Select At Least One List");
                        }
                      }}
                    >
                      Status Change
                    </Button>
                    <StatusChangemodal
                      show={modalShow}
                      ids={selectAllChecked}
                      onHide={() => setModalShow(false)}
                    />
                  </div>

                  <div className="status-button">
                    <Button
                      className="btn tansfer-btn"
                      onClick={() => {
                        if (selectAllChecked.length) {
                          setModalComplaint(true);
                        } else {
                          alert("Please Select At Least One List");
                        }
                      }}
                    >
                      Complaint Transfer
                    </Button>
                    <CompalintTransferModal
                      ids={selectAllChecked}
                      show={modalComplaint}
                      onHide={() => setModalComplaint(false)}
                    />
                  </div>

                  <div className="status-button">
                    <Button
                      className="btn tansfer-btn"
                      onClick={() => {
                        if (selectAllChecked.length) {
                          setModalScheduleDate(true);
                        } else {
                          alert("Please Select At Least One List");
                        }
                      }}
                    >
                      Complaint Schedule Time & Date
                    </Button>
                    <ScheduleDateChangemodal
                      ids={selectAllChecked}
                      getAllData={getAllData}
                      show={modalScheduleDate}
                      onHide={() => setModalScheduleDate(false)}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="status-button text-end my-3">
                  <Button
                    variant="primary"
                    className="add-neww-btn"
                    onClick={() => setModalAdd(true)}
                  >
                    <FontAwesomeIcon
                      icon="fa-solid fa-plus"
                      className="fonticn"
                    />{" "}
                    Add New Complaint
                  </Button>
                  <AddNewComplaintModal
                    view={view}
                    show={modalAdd}
                    onHide={() => {
                      setModalAdd(false);
                      setview(false);
                    }}
                  />
                </div>
              </div>
            </Row>
            <Row>
              <div className="table-sec-main">
                <Table responsive>
                  <thead>
                    <tr className="heads-main">
                      <th>
                        <input
                          type="checkbox"
                          value="selectAll"
                          checked={
                            allChecked.length === selectAllChecked.length
                          }
                          onChange={handleChange}
                          id="selectAll"
                          className="me-1"
                        ></input>
                        Select
                      </th>
                      <th>Complatint No.</th>
                      <th>Customer Name</th>
                      <th>Complaint Type</th>
                      <th>Visit Type</th>
                      <th>Charges (rs)</th>
                      <th>Bill</th>
                      <th>Description</th>
                      <th>Call by whom</th>
                      <th>Reference no</th>
                      <th>Service Engineer</th>
                      <th>Schedule Date</th>
                      <th>Schedule Time</th>
                      <th>Item Name</th>
                      <th>Item Serial Number</th>
                      {/* <th>Status</th> */}
                      <th>Source</th>
                      <th>Service Call Number</th>
                      <th>Completion Date</th>
                      <th>Handled by</th>
                      <th>Mobile Number for SMS</th>
                      <th>Remark</th>
                      <th>Case Priority</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.data?.map((d, index) => (
                      <tr className="heads-main" key={index}>
                        <td>
                          <input
                            type="checkbox"
                            className="form-check-input"
                            value={d?.id}
                            name="perselected"
                            checked={selectAllChecked.includes(d?.id)}
                            onChange={handleChange}
                            id="flexCheckDefault"
                          ></input>
                        </td>
                        <td>{d.complaints_no}</td>
                        <td>{d.customer?.name}</td>
                        <td>{d.complaint_type?.name}</td>
                        <td>{d.visit_type?.name}</td>
                        <td>{d.charges}</td>
                        <td>{d.bill}</td>
                        <td>{d.description}</td>
                        <td>{d.call_by_whom}</td>
                        <td>{d.reference_no}</td>
                        <td>{d.service_engineer?.name}</td>
                        <td>{d.schedule_date}</td>
                        <td>{d.schedule_time}</td>
                        <td>{d.product?.name}</td>
                        <td>{d.item_serial_no}</td>
                        {/* <td>XXXXX</td> */}
                        <td>{d.complaint_source?.name}</td>
                        <td>{d.service_call_no}</td>
                        <td>{d.completion_time}</td>
                        <td>{d.handled_by?.name}</td>
                        <td>{d.mobile_no_for_sms}</td>
                        <td>{d.remark}</td>
                        <td
                          style={{
                            color:
                              d.complaint_priority?.name === "High"
                                ? "#F42B2D"
                                : d.complaint_priority?.name === "Medium"
                                ? "#E2D300"
                                : "#38EF7D",
                          }}
                        >
                          {d.complaint_priority?.name}
                        </td>
                        <td>
                          <div
                            className="status-table-data"
                            style={{
                              backgroundColor:
                                d.complaint_status?.name === "Rejected"
                                  ? "#F42B2D"
                                  : d.complaint_status?.name === "Resolved"
                                  ? "#FFD500"
                                  : d.complaint_status?.name === "Hold"
                                  ? "#D1D801"
                                  : d.complaint_status?.name === "New"
                                  ? "#9DD9F3"
                                  : "",
                              color:
                                d.complaint_status?.name === "Rejected"
                                  ? "#FFF"
                                  : "#000",
                            }}
                          >
                            {d.complaint_status?.name}
                          </div>
                        </td>
                        <td>
                          <FontAwesomeIcon
                            icon="fa-solid fa-eye"
                            onClick={() => {
                              setModalAdd(d?.id);
                              setview(true);
                            }}
                            className="action-icon me-2"
                          />
                          {d?.complaint_status_id !== 3 &&
                            d?.complaint_status_id !== 4 && (
                              <FontAwesomeIcon
                                icon="fa-solid fa-pen"
                                onClick={() => setModalAdd(d?.id)}
                                className="action-icon me-2"
                              />
                            )}
                          <FontAwesomeIcon
                            icon="fa-solid fa-trash-can"
                            onClick={() => deleteRecord(d?.id)}
                            // onClick={() => deleteRecord(d?.id)}
                            className="action-icon me-2"
                          />{" "}
                          {/*delete record modal  */}
                          <DeleteModal
                            show={modelDelete}
                            onHide={() => setModelDelete(false)}
                          />
                          <FontAwesomeIcon
                            icon="fa-solid fa-clock-rotate-left"
                            onClick={() => {
                              setstatusandtransfer(d);
                              setLgShow(true);
                            }}
                            className="action-icon"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              <div className="paginationss mt-4">
                <CustomPagination
                  currentPage={currentPage}
                  totalPages={data?.totalPages}
                  handlePageChange={handlePageChange}
                />

                {/* <Pagination>
                  {currentPage !== 1 && (
                    <Pagination.Prev
                      onClick={() => handlePageChange(currentPage - 1)}
                    />
                  )}
                  {[...Array(data?.totalPages).keys()].map((page) => {
                    // Show the first 3 pages
                    if (currentPage <= 2 && page < 3) {
                      return (
                        <Pagination.Item
                          key={page + 1}
                          active={page + 1 === data?.currentPage}
                          onClick={() => handlePageChange(page + 1)}
                        >
                          {page + 1}
                        </Pagination.Item>
                      );
                    }

                    // Show the last 3 pages
                    if (
                      currentPage >= data?.totalPages - 1 &&
                      page >= data?.totalPages - 3
                    ) {
                      return (
                        <Pagination.Item
                          key={page + 1}
                          active={page + 1 === data?.currentPage}
                          onClick={() => handlePageChange(page + 1)}
                        >
                          {page + 1}
                        </Pagination.Item>
                      );
                    }

                    // Show ellipsis for the remaining pages
                    if (
                      page >= currentPage - 2 &&
                      page <= currentPage + 2 &&
                      page > 2 &&
                      page < data?.totalPages - 3
                    ) {
                      return (
                        <Pagination.Item
                          key={page + 1}
                          active={page + 1 === data?.currentPage}
                          onClick={() => handlePageChange(page + 1)}
                        >
                          {page + 1}
                        </Pagination.Item>
                      );
                    }

                    // Show ellipsis
                    if (
                      (page === 2 && currentPage > 4) ||
                      (page === data?.totalPages - 4 &&
                        currentPage < data?.totalPages - 3)
                    ) {
                      return <Pagination.Ellipsis key={page + 1} />;
                    }

                    return null;
                  })}
                  {currentPage !== data?.totalPages && (
                    <Pagination.Next
                      onClick={() => handlePageChange(currentPage + 1)}
                    />
                  )}
                </Pagination> */}

                {/* <Pagination>
                  {currentPage !== 1 && (
                    <Pagination.Prev
                      onClick={() => handlePageChange(currentPage - 1)}
                    />
                  )}
                  {[...Array(data?.totalPages).keys()].map((page) => (
                    <Pagination.Item
                      key={page + 1}
                      active={page + 1 === data?.currentPage}
                      onClick={() => handlePageChange(page + 1)}
                    >
                      {page + 1}
                    </Pagination.Item>
                  ))}
                  <Pagination.Ellipsis />
                  {currentPage !== data?.totalPages && (
                    <Pagination.Next
                      onClick={() => handlePageChange(currentPage + 1)}
                    />
                  )}
                </Pagination> */}
                {/* <Pagination>
                  <Pagination.Prev />
                  <Pagination.Item>{1}</Pagination.Item>
                  <Pagination.Item>{2}</Pagination.Item>
                  <Pagination.Ellipsis />
                  <Pagination.Item>{10}</Pagination.Item>
                  <Pagination.Next />
                </Pagination> */}
              </div>
            </Row>
          </div>
        </Row>
      </section>
      {/* History Modal */}
      <div>
        <Modal
          size="lg"
          show={lgShow}
          onHide={() => setLgShow(false)}
          aria-labelledby="example-modal-sizes-title-lg"
          className="history-modal"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              <h6 className="mt-2">
                History{" "}
                <span>
                  {" "}
                  ( Customer Name : {statusandtransfer?.customer_name} ,
                  Complaint Number : {statusandtransfer?.complaints_no})
                </span>
              </h6>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Row>
                <Nav variant="pills" className="">
                  <Nav.Item>
                    <Nav.Link eventKey="first">Status Change</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="second">Complaint Transfer</Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    {" "}
                    <Table responsive bordered>
                      <thead>
                        <tr>
                          <th>Status Change</th>
                          <th>Remark</th>
                          <th>Date</th>
                          <th>Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        {statusandtransfer?.complaint_status_histories?.map(
                          (data, index) => (
                            <tr key={index}>
                              <td>
                                <p className="status-table-p">
                                  {data?.user?.name} Change status to{" "}
                                  <span className="statue-span">
                                    {data?.complaint_status?.name}
                                  </span>
                                </p>
                              </td>
                              <td>{data?.remark}</td>
                              <td>{data?.date}</td>
                              <td>{data?.time}</td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </Table>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    {" "}
                    <Table responsive bordered>
                      <thead>
                        <tr>
                          <th>Transfer</th>
                          <th>Date</th>
                          <th>Time</th>
                          <th>Remark</th>
                        </tr>
                      </thead>
                      <tbody>
                        {statusandtransfer?.complaint_transfer_histories?.map(
                          (data, index) => (
                            <tr key={index}>
                              <td>
                                <p className="table-p-tag">
                                  <span className="p-tag-span1">
                                    {data?.transfer_by?.name}
                                  </span>{" "}
                                  Transfer This Complaint To{" "}
                                  <span className="p-tag-span1">
                                    {data?.transfer_to?.name}
                                  </span>
                                </p>
                              </td>
                              <td>{data?.date}</td>
                              <td>{data?.time}</td>
                              <td>{data?.remark}</td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </Table>
                  </Tab.Pane>
                </Tab.Content>
              </Row>
            </Tab.Container>
          </Modal.Body>
        </Modal>
      </div>
      {/* <Modal show={showFeedback} onHide={handleCloseFeedback} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Feedback</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-holder">
            <p>Name</p>
          </div>
          <div className="image-holder">
            <img
              className=""
              src={
                process.env.PUBLIC_URL +
                "/assets/complaint-management/icon/user.png"
              }
            />
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseFeedback}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCloseFeedback}>
            Save Changes
          </Button>
        </Modal.Footer> 
      </Modal> */}
    </>
  );
};

export default ComplaintListTable;
