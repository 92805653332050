import React, { useContext, useEffect, useState } from "react";
import "./ComplaintreportTable.css";
import search1 from "../../../../Components/Admin/assets/icons/search.png";
import { Row, Col, Form, Pagination, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import { Context } from "../../../../utils/context";
import CustomPagination from "../../../common/CustomPagination";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const ComplaintReportTable = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const { getData, getDownloadDataExcel, Select2Data } = useContext(Context);

  const [currentPage, setCurrentPage] = useState(1);

  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [status, setStatus] = useState("");
  const [searchStatus, setSearchStatus] = useState("");

  const [priority, setPriority] = useState("");
  const [searchPriority, setSearchPriority] = useState("");

  // const [perPage, setPerPage] = useState(5);
  const [search, setSearch] = useState("");

  const [reset, setReset] = useState();
  const [data, setData] = useState({ totalPages: 1, currentPage: 1 });
  const getAllData = async () => {
    const res = await getData(
      `/complaint-management/complaints?page=${currentPage}&term=${search} 
      &from=${from}
      &to=${to} 
      &complaint_status=${
        searchStatus?.value ? searchStatus?.value : ""
      }&complaint_priorities=${
        searchPriority?.value ? searchPriority?.value : ""
      }`
    );

    setData(res?.data);
    setSearch(res?.data?.search_name);
    setFrom(res?.data?.from);
    setTo(res?.data?.to);
    setStatus(res?.data?.search_name);

    const newData = res?.data?.data;
    if (newData) {
      const newIds = newData.map((d) => d?.id);
      setAllChecked(newIds);
    }
    const res2 = await getData("/masters/all-complaint-status");

    if (res2?.success) {
      setStatus(await Select2Data(res2?.data, "complaint_status_id"));
    }

    const res3 = await getData("/masters/all-complaint-priorities");

    if (res3?.success) {
      setPriority(await Select2Data(res3?.data));
    }
  };

  // useEffect(() => {
  //   getAllData();
  // }, []);

  useEffect(() => {
    getAllData();
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Select Check box
  // Select All Functionality
  const [formData, setFormData] = useState({
    user_id: "",
    lead_status_id: "",
    ids: [],
  });

  const [selectAllChecked, setSelectAllChecked] = useState([]);
  const [allChecked, setAllChecked] = useState([]);

  const handleSelectAll = async () => {
    await setSelectAllChecked(allChecked);
  };

  const handleChange = async (e) => {
    const { value, checked } = e.target;

    if (value === "selectAll") {
      if (checked) {
        handleSelectAll();
      } else {
        await setSelectAllChecked([]);
      }
    } else {
      if (checked) {
        await setSelectAllChecked([...selectAllChecked, Number(value)]);
      } else {
        await setSelectAllChecked(
          selectAllChecked.filter((e) => e !== Number(value))
        );
      }
    }
  };

  useEffect(() => {
    setFormData({ ...formData, ids: selectAllChecked });
    if (selectAllChecked.length === 0) {
      // LeadTransferHide();
      setFormData({ user_id: "", lead_status_id: "", ids: [] });
    }
    getAllData();
  }, [selectAllChecked, reset]);

  const HandleDownload = async () => {
    if (selectAllChecked.length == 0) {
      alert("Please Select Atleast One Record");
    } else {
      try {
        await getDownloadDataExcel(
          "/complaint-management/complaints/download",
          selectAllChecked,
          "Complaint List"
        );
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  return (
    <>
      <section className="complaint-list-table ">
        <div className="row me-0 ms-0">
          <div className="complaint-report-title">
            <h3>Complaints Report</h3>
          </div>
          <div className="main-class-report-table">
            <Row>
              <Col xl={3} lg={3} md={5} sm={12}>
                <Form.Label>Search</Form.Label>
                <input
                  type="text"
                  className="form-control"
                  id=""
                  placeholder="Customer Name"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target?.value);
                  }}
                />
              </Col>
              <Col xl={2} lg={3} md={5} sm={12}>
                <Form.Label>Complaint Status</Form.Label>
                <Select
                  placeholder="Status"
                  className="custom-select"
                  onChange={(e) => {
                    setSearchStatus(e); // Access the selected value using e.target.value
                  }}
                  value={searchStatus} // You can set the value prop if you want to pre-select an option
                  options={status}
                />
              </Col>

              <Col xl={2} lg={3} md={5} sm={12}>
                <Form.Label>Schedule From</Form.Label>
                <input
                  type="Date"
                  className="form-control"
                  id=""
                  value={from}
                  onChange={(e) => {
                    setFrom(e.target?.value);
                  }}
                />
              </Col>
              <Col xl={2} lg={3} md={5} sm={12}>
                <Form.Label> Schedule To</Form.Label>
                <input
                  type="Date"
                  className="form-control"
                  id=""
                  value={to}
                  onChange={(e) => {
                    setTo(e.target?.value);
                  }}
                  min={from}
                />
              </Col>

              <Col xl={2} lg={3} md={5} sm={12}>
                <Form.Label>Sory By</Form.Label>
                <Select
                  placeholder="Priority"
                  className="custom-select"
                  onChange={(e) => {
                    setSearchPriority(e);
                  }}
                  value={searchPriority}
                  options={priority}
                />
              </Col>
              <Col xl={5} lg={5} md={6} sm={12}>
                <Row className="mt-1">
                  <Col xl={2} lg={2} md={2} sm={3}>
                    <Form.Label></Form.Label>
                    <div className="Search me-2">
                      <button
                        type="button"
                        onClick={getAllData}
                        className="reset-btn"
                      >
                        <img src={search1} className="search" alt="" />
                      </button>
                    </div>
                  </Col>
                  <Col xl={3} lg={3} md={2} sm={6}>
                    <Form.Label></Form.Label>
                    <div className="Search-1">
                      <button
                        type="button"
                        onClick={() => {
                          setSearch("");
                          setFrom("");
                          setTo("");
                          setSearchStatus("");
                          setSearchPriority("");
                          setReset(!reset);
                        }}
                        className="reset-btn"
                      >
                        Reset
                      </button>
                    </div>
                  </Col>
                  <Col xl={7} lg={6} md={8} sm={6}>
                    <Form.Label>Download data in Excel</Form.Label>
                    <br />
                    <FontAwesomeIcon
                      icon="fa-solid fa-file-lines"
                      className="pdf-icon"
                      variant="success"
                      // className="btn btn-view"
                      onClick={HandleDownload}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <div className="brownborder-report mt-3"></div>
            <Row>
              <div className="table-sec-main mt-3">
                <Table responsive>
                  <thead>
                    <tr className="heads-main">
                      <th>
                        <input
                          type="checkbox"
                          value="selectAll"
                          checked={
                            allChecked.length === selectAllChecked.length
                          }
                          onChange={handleChange}
                          id="selectAll"
                          className="me-1"
                        ></input>
                        Select
                      </th>
                      <th>Complatint No.</th>
                      <th>Customer Name</th>
                      <th>Complaint Type</th>
                      <th>Visit Type</th>
                      <th>Charges (rs)</th>
                      <th>Bill</th>
                      <th>Description</th>
                      <th>Call by whom</th>
                      <th>Reference no</th>
                      <th>Service Engineer</th>
                      <th>Schedule Date</th>
                      <th>Item Name</th>
                      <th>Item Serial Number</th>
                      {/* <th>Status</th> */}
                      <th>Source</th>
                      <th>Service Call Number</th>
                      <th>Completion Time</th>
                      <th>Handled by</th>
                      <th>Mobile Number for SMS</th>
                      <th>Remark</th>
                      <th>History</th>
                      <th>Case Priority</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.data?.map((d, index) => (
                      <tr className="heads-main" key={index}>
                        <td>
                          <input
                            type="checkbox"
                            className="form-check-input"
                            // type="checkbox"
                            value={d?.id}
                            name="perselected"
                            checked={selectAllChecked.includes(d?.id)}
                            onChange={handleChange}
                            id="flexCheckDefault"
                          ></input>
                        </td>
                        <td>{d.complaints_no}</td>
                        <td>{d.customer?.name}</td>
                        <td>{d.complaint_type?.name}</td>
                        <td>{d.visit_type?.name}</td>
                        <td>{d.charges}</td>
                        <td>{d.bill}</td>
                        <td>{d.description}</td>
                        <td>{d.call_by_whom}</td>
                        <td>{d.reference_no}</td>
                        <td>{d.service_engineer?.name}</td>
                        <td>{d.schedule_date}</td>
                        <td>{d.product?.name}</td>
                        <td>{d.item_serial_no}</td>
                        {/* <td>XXXXX</td> */}
                        <td>{d.complaint_source?.name}</td>
                        <td>{d.service_call_no}</td>
                        <td>{d.completion_time}</td>
                        <td>{d.handled_by?.name}</td>
                        <td>{d.mobile_no_for_sms}</td>
                        <td>{d.remark}</td>
                        <td>{d.history}</td>
                        {/* <td>{d.complaint_priority?.name}</td> */}
                        {/* <td>{d.complaint_status?.name}</td> */}
                        <td
                          style={{
                            color:
                              d.complaint_priority?.name === "High"
                                ? "#F42B2D"
                                : d.complaint_priority?.name === "Medium"
                                ? "#E2D300"
                                : "#38EF7D",
                          }}
                        >
                          {d.complaint_priority?.name}
                        </td>
                        <td>
                          <div
                            className="status-table-data"
                            style={{
                              backgroundColor:
                                d.complaint_status?.name === "Rejected"
                                  ? "#F42B2D"
                                  : d.complaint_status?.name === "Resolved"
                                  ? "#FFD500"
                                  : d.complaint_status?.name === "Hold"
                                  ? "#D1D801"
                                  : d.complaint_status?.name === "New"
                                  ? "#9DD9F3"
                                  : "",
                              color:
                                d.complaint_status?.name === "Rejected"
                                  ? "#FFF"
                                  : "#000",
                            }}
                          >
                            {d.complaint_status?.name}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              <div className="paginationss mt-4">
                <CustomPagination
                  currentPage={currentPage}
                  totalPages={data?.totalPages}
                  handlePageChange={handlePageChange}
                />
                {/* <Pagination>
                  <Pagination.Prev />
                  <Pagination.Item>{1}</Pagination.Item>
                  <Pagination.Item>{2}</Pagination.Item>
                  <Pagination.Ellipsis />
                  <Pagination.Item>{10}</Pagination.Item>
                  <Pagination.Next />
                </Pagination> */}
              </div>
            </Row>
          </div>
        </div>
      </section>
    </>
  );
};

export default ComplaintReportTable;
