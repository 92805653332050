import { useContext, useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Dropdown, Pagination, Row, Table } from "react-bootstrap";
import "./DashboardTable.css";
import { BarChart } from "@mui/x-charts";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import AddNewComplaintModal from "../../compliant-list/all-table-modal/AddNewComplaintModal";
import { Link } from "react-router-dom";
import { Context } from "../../../../utils/context";
import CustomPagination from "../../../common/CustomPagination";

import CanvasJSReact from "@canvasjs/react-charts";

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const DashboardTable = () => {
  // Modal
  const [lgShow, setLgShow] = useState(false);

  // dropdown
  // const uData = [20, 5, 2, 25, 11, 3, 5, 10, 4, 5, 10, 15];
  // const pData = [20, 5, 2, 25, 11, 3, 5, 10, 4, 5, 10, 15];
  // const vData = [20, 5, 2, 25, 11, 3, 5, 10, 4, 5, 10, 15];
  // const wData = [20, 5, 2, 25, 11, 3, 5, 10, 4, 5, 10, 15];
  // const colors = ["#C4D3FC", "#FFD500", "#D1D801", "#F42B2D"];

  // const xLabels = [
  //   "Jan",
  //   "Feb",
  //   "March",
  //   "April",
  //   "May",
  //   "June",
  //   "Jul",
  //   "Aug",
  //   "Sep",
  //   "Oct",
  //   "Nov",
  //   "Dec",
  // ];

  const { getData, complaintstatus, setComplaintStatus } = useContext(Context);
  const [currentPage, setCurrentPage] = useState(1);
  // const [perPage, setPerPage] = useState(5);
  const [search, setSearch] = useState("");
  const [data, setData] = useState({ totalPages: 1, currentPage: 1 });
  const [dashboard, setDashborad] = useState({});
  const [dashboardchart, setDashboradChart] = useState({});

  console.log(dashboard);

  const getAllData = async () => {
    {
      const res = await getData(
        `/complaint-management/complaints?page=${currentPage}&term=${search}`
      );
      setData(res?.data);
    }
    {
      const res = await getData(`/complaint-management/dashboard`);
      setDashborad(res?.data);
    }

    {
      const res = await getData(`/complaint-management/dashboard/chart`);
      setDashboradChart(res?.data?.records);
      console.log(res?.data);
    }
  };

  useEffect(() => {
    getAllData();
  }, [currentPage, search, lgShow]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Chart
  const chartRef = useRef(null);

  const toggleDataSeries = (e) => {
    if (typeof e.dataSeries.visible === "undefined" || e.dataSeries.visible) {
      e.dataSeries.visible = false;
    } else {
      e.dataSeries.visible = true;
    }
    chartRef.current.render();
  };

  const options = {
    // animationEnabled: true,
    exportEnabled: true,
    title: {
      text: "Complaints",
      fontFamily: "verdana",
    },
    // axisY: {
    //   title: "in Eur",
    //   includeZero: true,
    //   prefix: "€",
    //   suffix: "k",
    // },
    toolTip: {
      shared: true,
      reversed: true,
    },
    legend: {
      verticalAlign: "center",
      horizontalAlign: "right",
      reversed: true,
      cursor: "pointer",
      // itemclick: toggleDataSeries,
    },
    data: [
      {
        type: "stackedColumn",
        name: "New",
        color: "#C4D3FC",
        showInLegend: true,
        // dataPoints: [
        //   { label: "Jan", y: 14 },
        //   { label: "Feb", y: 12 },
        //   { label: "Mar", y: 14 },
        //   { label: "Apr", y: 13 },
        //   { label: "May", y: 13 },
        //   { label: "Jun", y: 13 },
        //   { label: "Jul", y: 14 },
        //   { label: "Aug", y: 14 },
        //   { label: "Sept", y: 13 },
        //   { label: "Oct", y: 14 },
        //   { label: "Nov", y: 14 },
        //   { label: "Dec", y: 14 },
        // ],
        dataPoints: dashboardchart?.New,
      },
      {
        type: "stackedColumn",
        name: "Pending",
        color: "#FFD500",
        showInLegend: true,
        // dataPoints: [
        //   { label: "Jan", y: 13 },
        //   { label: "Feb", y: 13 },
        //   { label: "Mar", y: 15 },
        //   { label: "Apr", y: 16 },
        //   { label: "May", y: 17 },
        //   { label: "Jun", y: 17 },
        //   { label: "Jul", y: 18 },
        //   { label: "Aug", y: 18 },
        //   { label: "Sept", y: 17 },
        //   { label: "Oct", y: 18 },
        //   { label: "Nov", y: 18 },
        //   { label: "Dec", y: 18 },
        // ],
        dataPoints: dashboardchart?.Pending,
      },
      {
        type: "stackedColumn",
        name: "Resolved",
        showInLegend: true,
        color: "#D1D801",
        // dataPoints: [
        //   { label: "Jan", y: 13 },
        //   { label: "Feb", y: 13 },
        //   { label: "Mar", y: 15 },
        //   { label: "Apr", y: 15 },
        //   { label: "May", y: 15 },
        //   { label: "Jun", y: 15 },
        //   { label: "Jul", y: 16 },
        //   { label: "Aug", y: 17 },
        //   { label: "Sept", y: 17 },
        //   { label: "Oct", y: 18 },
        //   { label: "Nov", y: 19 },
        //   { label: "Dec", y: 20 },
        // ],
        dataPoints: dashboardchart?.Resolved,
      },
      {
        type: "stackedColumn",
        name: "Rejected",
        showInLegend: true,
        color: "#F42B2D",
        // dataPoints: [
        //   { label: "Jan", y: 14 },
        //   { label: "Feb", y: 8 },
        //   { label: "Mar", y: 6 },
        //   { label: "Apr", y: 6 },
        //   { label: "May", y: 5 },
        //   { label: "Jun", y: 5 },
        //   { label: "Jul", y: 6 },
        //   { label: "Aug", y: 3 },
        //   { label: "Sept", y: 9 },
        //   { label: "Oct", y: 5 },
        //   { label: "Nov", y: 8 },
        //   { label: "Dec", y: 2 },
        // ],
        dataPoints: dashboardchart?.Rejected,
      },
    ],
  };

  return (
    <>
      <section className="dashboard-section">
        <div className="row me-0 ms-0">
          <div className="dashboard-main">
            <div className="card-section">
              <Row>
                <Col xs={12} sm={12} md={6} lg={6} xl={3} xxl={3}>
                  <div className="main-cards clr1 mb-xxxl-0 mb-xl-0 mb-lg-3">
                    <div className="conts">
                      <div className="left-conts">
                        <p className="cont-top-text">New Complaint</p>
                        <h4 className="cont-no">
                          {dashboard?.complaint_registred}
                        </h4>
                      </div>
                      <div className="right-conts">
                        <img
                          className="ticket-img"
                          src={
                            process.env.PUBLIC_URL + "/assets/Image/tickets.png"
                          }
                        />
                        {/* <FontAwesomeIcon icon="fa-solid fa-envelope-open-text " className='iconses' /> */}
                      </div>
                    </div>
                    <div
                      className="bottom-conts"
                      onClick={() =>
                        setComplaintStatus({ value: 1, label: "New" })
                      }
                    >
                      <Link to="/complaint-list-table">
                        <p className="cont-text">
                          View Complaints{" "}
                          <FontAwesomeIcon
                            icon="fa-solid fa-greater-than"
                            className="arrow"
                          />
                        </p>
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={3} xxl={3}>
                  <div className="main-cards clr2 mb-xxxl-0 mb-xl-0 mb-lg-3">
                    <div className="conts">
                      <div className="left-conts">
                        <p className="cont-top-text">Pending Complaint</p>
                        <h4 className="cont-no">
                          {" "}
                          {dashboard?.pending_registred}
                        </h4>
                      </div>
                      <div className="right-conts">
                        <img
                          className="ticket-img"
                          src={
                            process.env.PUBLIC_URL + "/assets/Image/tickets.png"
                          }
                        />
                        {/* <FontAwesomeIcon icon="fa-solid fa-envelope-open-text " className='iconses' /> */}
                      </div>
                    </div>
                    <div
                      className="bottom-conts"
                      onClick={() =>
                        setComplaintStatus({ value: 2, label: "Hold" })
                      }
                    >
                      <Link to="/complaint-list-table">
                        {" "}
                        <p className="cont-text">
                          View Complaints{" "}
                          <FontAwesomeIcon
                            icon="fa-solid fa-greater-than"
                            className="arrow"
                          />
                        </p>
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={3} xxl={3}>
                  <div className="main-cards clr3 mb-xxxl-0 mb-xl-0 mb-lg-3">
                    <div className="conts">
                      <div className="left-conts">
                        <p className="cont-top-text">Resolved Complaint</p>
                        <h4 className="cont-no">
                          {" "}
                          {dashboard?.closed_registred}
                        </h4>
                      </div>
                      <div className="right-conts">
                        <img
                          className="ticket-img"
                          src={
                            process.env.PUBLIC_URL + "/assets/Image/tickets.png"
                          }
                        />
                        {/* <FontAwesomeIcon icon="fa-solid fa-envelope-open-text " className='iconses' /> */}
                      </div>
                    </div>
                    <div
                      className="bottom-conts"
                      onClick={() =>
                        setComplaintStatus({ value: 3, label: "Resolved" })
                      }
                    >
                      <Link to="/complaint-list-table">
                        {" "}
                        <p className="cont-text">
                          View Complaints{" "}
                          <FontAwesomeIcon
                            icon="fa-solid fa-greater-than"
                            className="arrow"
                          />
                        </p>
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={3} xxl={3}>
                  <div className="main-cards clr4 mb-xxxl-0 mb-xl-0 mb-lg-3">
                    <div className="conts">
                      <div className="left-conts">
                        <p className="cont-top-text">Rejected Complaint</p>
                        <h4 className="cont-no">
                          {dashboard?.rejected_registred}
                        </h4>
                      </div>
                      <div className="right-conts">
                        <img
                          className="ticket-img"
                          src={
                            process.env.PUBLIC_URL + "/assets/Image/tickets.png"
                          }
                        />
                        {/* <FontAwesomeIcon icon="fa-solid fa-envelope-open-text " className='iconses' /> */}
                      </div>
                    </div>
                    <div
                      className="bottom-conts"
                      onClick={() =>
                        setComplaintStatus({ value: 4, label: "Rejected" })
                      }
                    >
                      <Link to="/complaint-list-table">
                        {" "}
                        <p className="cont-text">
                          View Complaints{" "}
                          <FontAwesomeIcon
                            icon="fa-solid fa-greater-than"
                            className="arrow"
                          />
                        </p>
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>

            <div className="bar-section">
              <div className="bar-topcont">
                <div className="overviews">
                  <p className="over-p">Complaint Overview</p>
                </div>
                {/* <div className="opts">
                  <p className="opts-p">
                    Download{" "}
                    <FontAwesomeIcon
                      icon="fa-solid fa-download"
                      className="download-icn"
                    />
                  </p>

                  <Dropdown>
                    <Dropdown.Toggle variant="" id="dropdown-basic">
                      Monthly
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1" className="drops-itms">
                        Yearly
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-2" className="drops-itms">
                        Weekly
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div> */}
              </div>
              <div className="bar-main">
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <div className="bar-div">
                      <CanvasJSChart options={options} ref={chartRef} />
                      {/* <BarChart
                        height={377}
                        series={[
                          {
                            data: pData,
                            label: "Total",
                            id: "pvId",
                            stack: "total",
                          },
                          {
                            data: uData,
                            label: "Resolved",
                            id: "uvId",
                            stack: "total",
                          },
                          {
                            data: vData,
                            label: "Resolved",
                            id: "vvId",
                            stack: "total",
                          },
                          {
                            data: wData,
                            label: "Resolved",
                            id: "wvId",
                            stack: "total",
                          },
                        ]}
                        xAxis={[{ data: xLabels, scaleType: "band" }]}
                        colors={colors}
                      /> */}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>

          <div className="tables-sections">
            <Row>
              <Col xs={9} sm={9} md={12} lg={9} xl={9} xxl={9}>
                <div className="table-mains">
                  <div className="table-top-main">
                    <div className="top-left-text">
                      <p>Your Complaints</p>
                    </div>
                    <div className="top-right-text">
                      <Link to="/complaint-list-table">
                        {" "}
                        <p>View all</p>
                      </Link>
                    </div>
                  </div>
                  <div className="table-sec-main">
                    <Table responsive hover>
                      <thead>
                        <tr className="heads-main">
                          {/* <th>Select</th> */}
                          <th>Complatint No.</th>
                          <th>Customer Name</th>
                          <th>Complaint Type</th>
                          <th>Description</th>
                          <th>Call by whom</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.data?.map((d, index) => (
                          <tr className="heads-main" key={index}>
                            {/* <td>
                              <input type="checkbox"></input>
                            </td> */}
                            <td>{d.complaints_no}</td>
                            <td>{d.customer?.name}</td>
                            <td>{d.complaint_type?.name}</td>
                            <td>{d.description}</td>
                            <td>{d.call_by_whom}</td>
                            <td>
                              <div className="maim">
                                <div
                                  className="status-colum"
                                  style={{
                                    backgroundColor:
                                      d.complaint_status?.name === "Rejected"
                                        ? "#F42B2D"
                                        : d.complaint_status?.name ===
                                          "Resolved"
                                        ? "#FFD500"
                                        : d.complaint_status?.name === "Hold"
                                        ? "#D1D801"
                                        : "green",
                                    color:
                                      d.complaint_status?.name === "Rejected"
                                        ? "#FFFFFF"
                                        : "#000000",
                                  }}
                                >
                                  <p className="status">
                                    {d.complaint_status?.name}
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <div className="paginationss">
                      <CustomPagination
                        currentPage={currentPage}
                        totalPages={data?.totalPages}
                        handlePageChange={handlePageChange}
                      />
                      {/* <Pagination>
                        <Pagination.Prev />
                        <Pagination.Item>{1}</Pagination.Item>
                        <Pagination.Item>{2}</Pagination.Item>
                        <Pagination.Item>{3}</Pagination.Item>
                        <Pagination.Next />
                      </Pagination> */}
                    </div>
                  </div>
                </div>
              </Col>

              <Col xs={3} sm={3} md={12} lg={3} xl={3} xxl={3}>
                <div className="complants">
                  <div className="cont-main">
                    <div className="contects-main ">
                      <div className="contents-icons">
                        <FontAwesomeIcon
                          icon="fa-solid fa-phone"
                          className="iconsss"
                        />
                      </div>
                      <div className="contects-data">
                        <p className="textss">Manager Contact</p>
                        <p className="noss">+91 8876456789</p>
                      </div>
                    </div>

                    <div className="contects-main ">
                      <div className="contents-icons">
                        <FontAwesomeIcon
                          icon="fa-solid fa-phone"
                          className="iconsss"
                        />
                      </div>
                      <div className="contects-data">
                        <p className="textss">Manager Lead</p>
                        <p className="noss">+91 9756345678</p>
                      </div>
                    </div>

                    <div className="contects-main ">
                      <div className="contents-icons">
                        <FontAwesomeIcon
                          icon="fa-solid fa-phone"
                          className="iconsss"
                        />
                      </div>
                      <div className="contects-data">
                        <p className="textss">Support Team</p>
                        <p className="noss">020 555‑0175</p>
                      </div>
                    </div>
                  </div>

                  <div>
                    <button
                      className="comp-button"
                      onClick={() => setLgShow(true)}
                    >
                      {" "}
                      <FontAwesomeIcon
                        icon="fa-solid fa-plus"
                        className="fonticn"
                      />{" "}
                      Add New Complaint
                    </button>
                    <AddNewComplaintModal
                      show={lgShow}
                      onHide={() => setLgShow(false)}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </section>
    </>
  );
};

export default DashboardTable;
